@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@300&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Bebas+Neue&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@300;700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Bebas+Neue&family=Poppins:wght@300&display=swap');


/*--------------------------------------------- Navbar -----------------------------------------------------*/

.nav {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.nav .nav__heading a {
  color: #ff5079;
  font: normal normal bold 110px/90px 'Bebas Neue', cursive;
  letter-spacing: 0px;
  opacity: 1;
  

}
.nav .nav__menu .nav__menu--item{
  text-align: left;
  font: normal normal medium 25px/38px poppins;
  letter-spacing: 0px;
  color: #ff5079;
  opacity: 1;
  margin-right: 60px;
}
/*------------------------------------------- Woman on break --------------------------------------------*/

.Afternav__heading {
  font: normal normal 500 17px/8px 'Poppins', sans-serif;
  color: #4b426b;
  margin-top: -10px;
}
.Afternav__heading--letter {
  font: normal normal 600 16px/20px Poppins;
  letter-spacing: 4.9px;
  color: #4b426b;
}

.Afternav__rightside {
  font: normal normal bold 75px/90px Bebas Neue;
  letter-spacing: 0px;
  color: #4b426b;
  opacity: 1;
  margin: auto;
}
/*----------------------------------------------WobImage section----------------------------------------*/
.mainsection{
  width: 100%;
}

.mainsection .mainsection__image {
  background: transparent url('./../../../assets/images/background/wob.png') 0%  no-repeat;
  background-size: 100%;
  opacity: 1;
  height: 390px;
  

  
}

.imagesection__text {
 
  display: flex;
  justify-content: center;
  align-items: center;
  font: normal normal 300 25px/35px Poppins;
  color: #4b426b;
  opacity: 1;
 
}
.form {
  text-align: center;
  width: 100%;
}

.form__input{
  
  margin-bottom: 50px;
  width: 50%;
  border: 3px solid #4b426b;
  border-radius: 41px;
  opacity: 1;
  padding: 9px;
  outline: none;
}
.search-input-btn {
 
  width: 77px;
  height:50px;
margin-left: -80px;
  background-color: #ff5079;
  padding: 9px;
  border: 3px solid none;
  border-radius: 41px;
  outline: none;
  
}

.form__btn {
  margin-left: 20px;
  border: 3px solid #4b426b;
  border-radius: 41px;
  opacity: 1;
  padding: 9px;
  width: 150px;
  color: #ff5079;
  background-color: #ffffff;
  cursor: pointer;
}

.form__btn:focus{
  outline: none;
}

/*----------------------------------------Carousel----------------------------------------------*/

#sampleSlide {
  margin: 0px;
}

/*-----------------------------------Born Starting------------------------------------------------*/
.mainCarosal{
  background: #4B426B 0% 0% no-repeat padding-box;
opacity: 1;
width: 100%;
padding: 30px;
height: 80vh;
}
.main__carosal--img {
  background: transparent url('./../../../assets/images/background/BORN.png') 700px 150px
    no-repeat;
  opacity: 1;
  background-size: 39%;
  height: 80vh;
}
.main__carosal--title{
  font: normal normal 80 25px/35px Poppins;
letter-spacing: 0px;
color: #FFFFFF;
}
/*-------------------------------------Branding & Promise(Slider1)---------------------------------------*/

.mainbrandingCarosal {
  background: #f8e76c 0% 0% no-repeat padding-box;
  opacity: 1;
  width: 100%;
  padding: 30px;
  height: 80vh;
}
.main__branding--img {
  background: transparent url('./../../../assets/images/background/womenOnBreak.png') 600px 70px
    no-repeat;
  opacity: 1;
  background-size: 50%;
  height: 80vh;
}
.branding__heading,
.opportunity__heading,
.assurance__heading,
.nurturing__heading,.main__heading {
  font: normal normal bold 275px/250px Bebas Neue;
  color: #ffffff;
  opacity: 1;
}
.branding__heading--letter,
.opportunity__heading--letter,
.assurance__heading--letter,
.nurturing__heading--letter {
  color: #ef527a;
}

.branding__carosal--heading,
.opportunity__carosal--heading,
.assurance__carosal--heading,
.nurturing__carosal--heading {
  font: normal normal 600 40px/68px 'Poppins', sans-serif;
  color: #ff5079;
  margin: 0px;
}
.branding__carosal--List,
.opportunity__carosal--List,
.assurance__carosal--List,
.nurturing__carosal--List {
  margin: 0px;
  font: normal normal 23px/30px Poppins;
  color: #ff5079;
  width: 100%;
}

/*---------------------------------opportunity(Slider2)---------------------------------------*/

.mainopportunityCarosal {
  background: #4c426c 0% 0% no-repeat padding-box;
  opacity: 1;
  width: 100%;
  padding: 30px;
  height: 80vh;
}
.main__opportunity--img {
  background: transparent url('./../../../assets/images/background/opporunity.png') 430px 55px
    no-repeat;
  opacity: 1;
  background-size: 70%;
  height: 80vh;
}

/*-----------------------------------assurance(Slider3)-------------------------------------*/

.assurance__carosal--heading {
  font-size: 30px;
}
.assurance__heading {
  margin: 0;
}
.mainassurancecarosal {
  background: #20d0da 0% 0% no-repeat padding-box;
  opacity: 1;
  width: 100%;
  padding: 30px;
  height: 80vh;
}
.main__assurance--img {
  background: transparent url('./../../../assets/images/background/assurance.png') 350px 80px 
  no-repeat;
  opacity: 1;
  background-size: 70%;
  height: 80vh;
}

/*-----------------------------------Nurturing(Slider4)------------------------------------*/

.mainnurturingcarosal {
  background: #4b426b 0% 0% no-repeat padding-box;
  opacity: 1;
  width: 100%;
  padding: 30px;
  height: 80vh;
}

.main__nurturing--img {
background: transparent url('./../../../assets/images/background/nurturing.png') 480px 20px 
no-repeat;
  opacity: 1;
  background-size: 62%;
  height: 80vh;
}

.slider-item {
  font-size: 100px;
}

.carousel__heading {
  font: normal normal bold 70px/96px Bebas Neue;
  color: #4b426b;
  opacity: 1;
}
.carousel__sub-heading,
.carousel__sub-heading__letter {
  font: normal normal 300 35px/35px Poppins;
  color: #4b426b;
}
.carousel__sub-heading__letter {
  font-weight: 600;
}


/*----------------------------------- JOB opportunities----------------------------------------*/

.wobjobs{
  margin-bottom: 200px;
}
.wobjobs__heading {
  font: normal normal bold 70px/181px Bebas Neue;
  color: #4b426b;
  opacity: 1;
}

.wobjobs__section--card p {
  background: #ffffff 0% 0% no-repeat padding-box;
  box-shadow: 0px 6px 9px #00000029;
  border-radius: 30px;
  opacity: 1;
}

.wobjobs__title p{
  text-align: center;
  font: normal normal 300 43px/50px Poppins;
  letter-spacing: 0px;
  color: #4B426B;
  
}
.wobjobs__title p a{
  font-weight: 600;
  color: #4B426B;
}


/* ----------------------------------------------------Footer----------------------------------------------*/

.wobfooter{
  background: #DCDCE0 0% 0% no-repeat;
opacity: 1;
height: 230px;
width: 100%;
}

@media only screen and (max-width: 600px) {
  .main__branding--img {
    background-size: 70%;
    
  }
  .mainbrandingCarosal,.mainopportunityCarosal,.mainassurancecarosal,
  .mainnurturingcarosal,.mainCarosal{
    height: 500px;
  }
  .branding__heading,
  .opportunity__heading,
  .assurance__heading,
  .nurturing__heading,
  .main__heading {
   text-align: center;
    font: normal normal bold 160px/150px Bebas Neue;
    color: #ffffff;
    opacity: 1;
  }
 
  .branding__carosal--heading,
  .assurance__carosal--heading,
  .nurturing__carosal--heading,
  .opportunity__carosal--heading {
    text-align: center;
    font: normal normal 600 30px/50px 'Poppins', sans-serif;
    color: #ff5079;
    padding-right: 10px;
  }
  .carousel__heading {
    font: normal normal bold 50px/45px Bebas Neue;
    margin-top: 10px;
  }
  .carousel__sub-heading,
  .carousel__sub-heading__letter{
    font: normal normal 300 25px/25px Poppins;
    color: #4b426b;
  }
  .main__carosal--title{
    font: normal normal 300 25px/25px Poppins;
    color: #ffffff;
  }
  .carousel__sub-heading__letter {
    font-weight: 600;
  }
 
 .wobjobs__heading {
    font: normal normal bold 50px/50px Bebas Neue;
  text-align: center;
  }
  
  .wobjobs__section--card p {
    background: #ffffff 0% 0% no-repeat padding-box;
    box-shadow: 0px 6px 9px #00000029;
    border-radius: 30px;
    opacity: 1;
  }
  
  .wobjobs__title p{
    text-align: center;
    font: normal normal 300 43px/50px Poppins;
    letter-spacing: 0px;
    color: #4B426B;
    
  }
  .wobjobs__title p span{
    font-weight: 600;
  
  }
  /* .opportunity__carosal--title {
  
    padding-right: 10px;
    font-size: 20px;
  } */
  
}
