// Modal
.custom-modal-v1 {
  width: 100% !important;
  max-width: 1000px;

  .ant-modal-close {
    right: 0.5rem;
    top: 0.5rem;
    border-radius: 50%;
    background-color: rgba(0, 0, 0, 0.3);
    color: $gray-200;
  }

  .ant-modal-close-x {
    width: 1.5rem;
    height: 1.5rem;
    line-height: 1.5rem;
    font-size: $font-size-sm;
    opacity: $text-inactive;
    transition: opacity 0.3s;
    &:hover {
      opacity: 1;
    }
  }

  .ant-modal-body {
    padding: 0;
  }
}
