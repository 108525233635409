$theme-gradient: linear-gradient(to top, #f02a5a 0%, #c93d12 100%);

.profile-card-v1 {
  border: 1px solid rgba(0, 0, 0, 0.1);
  padding: 1rem 0.5rem;
  border-radius: $border-radius;
  background-color: $white;
  text-align: center;

  img {
    margin-bottom: 1.25rem;
    max-width: 100px;
    border-radius: 50%;
  }

  h4 {
    margin: 0;
    font-weight: $font-weight-500;
    font-size: $font-size-base;
    line-height: 1.625rem;
  }

  span {
    opacity: $text-secondary;
  }
}

.profile-card-v2 {
  border: 1px solid rgba(0, 0, 0, 0.1);
  padding: 1rem;
  border-radius: $border-radius;
  background-color: $white;
  text-align: center;
  min-height: 220px;
  // background: transparent linear-gradient(132deg, #24c6dc 0%, var(--unnamed-color-514a9d) 100%) 0%
  //   0% no-repeat padding-box;
  // background: transparent linear-gradient(132deg, #24c6dc 0%, #514a9d 100%) 0% 0% no-repeat
  //   padding-box;
  background-image: $theme-gradient;
  // background-image: linear-gradient(to top, #f77062 0%, #fe5196 100%);

  color: #fff;
  img {
    margin-bottom: 1.25rem;
    width: 100px;
    height: 100px;
    border-radius: 50%;
    box-shadow: 0 0 0 5px rgba(0, 0, 0, 0.1), 0 0 10px rgba(255, 255, 255, 0.2);
  }

  h4 {
    margin: 0;
    font-weight: $font-weight-500;
    font-size: 1.25rem;
    line-height: 1.625rem;
    margin: 5px;
  }

  h4 + span {
    display: block;
    opacity: $text-secondary;
    margin-bottom: 0.875rem;
  }
}
