@import "variables";
@import "ui/mixins";

// Core CSS
@import "ui/utilities";
@import "ui/type";
@import "ui/button";
@import "ui/tables-responsive";

// Components
@import "ui/badge";
@import "ui/blockquote";
@import "ui/box";
@import "ui/callout";
@import "ui/call-to-action";
@import "ui/card-blog";
@import "ui/card-box";
@import "ui/card-form";
@import "ui/card-icon";
@import "ui/card-image";
@import "ui/card-number";
@import "ui/card-portfolio";
@import "ui/card-product";
@import "ui/card-profile";
@import "ui/cover";
@import "ui/feature-callout";
@import "ui/forms";
@import "ui/hero";
@import "ui/hover";
@import "ui/hover-basic";
@import "ui/hover-link";
@import "ui/icon";
@import "ui/icon-social";
@import "ui/images";
@import "ui/list";
@import "ui/loader";
@import "ui/pricing-tables";
@import "ui/ribbon";
@import "ui/sash";
@import "ui/testimonials";
@import "ui/timeline";

// ******** FONTS IMPORTS ********
@import url("https://fonts.googleapis.com/css2?family=Lato&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Nunito&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Open+Sans:wght@600;700&display=swap");
@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@600&display=swap');
// ******** FONTS IMPORTS ENDS ********

// ******** COLORS  ********
$primary-color: #68439a;
$secondary-color: #aa5b8c;
$white-color: #ffffff;
$black-color: #000000;
$theme-gradient: linear-gradient(160deg, #68439a 0%, #aa5b8c 100%);

$theme-color-lightgrey: #d9d9d9;
$theme-color-darkgrey: #8d8787;

$new-primary-color:#4B426B;

// ******** COLORS ENDS ********

// ******** MIXINS  ********
@mixin bg-white {
  background: #fff !important;
}
@mixin custom-scrollbar {
  text-align: justify;
  overflow-y: auto;
  padding-left: 4px;
  @media all and (min-width: 320px) 
  {
    padding-left: 0px;
  }
  &::-webkit-scrollbar {
    width: 8px !important;
  }
  &::-webkit-scrollbar-thumb {
    background: transparent linear-gradient(250deg, $primary-color 0%, $secondary-color 100%) 0% 0% no-repeat
      padding-box;
    border-radius: 3px;
  }
}
@mixin section-header-title {
  display: flex;
  justify-content: center;
  margin-bottom: 40px;
  font-family: 'Poppins', sans-serif;
  color: $primary-color;
}
@mixin widget {
  color: #fff;
  padding: 15px;
  font-family: 'Poppins', sans-serif;
  display: block;
}

@mixin navbarSize {
  font-family: 'Poppins', sans-serif;
  color:$new-primary-color;
  font-weight: 500;
  font-size: 16px;
  @media screen and (max-width: 580px) {
    font: size 25pt;
    font-weight: 600;
    font-family: 'Poppins', sans-serif;
   }
}
// ******** MIXINS ENDS  ********

// ******** TYPOGRAPHY ********
$header-fonts: "Open Sans", sans-serif;
// h1,
// h2,
// h3,
// h4,
// h5,
// h6 {
//   font-family: $header-fonts;
// }
.section-heading {
  margin: 40px 0;
  color: $primary-color;
  font-weight: 800;
  font-size: 48px;
}

// ******** TYPOGRAPHY ENDS ********

.bg-white {
  background-color: #fff !important;
}

.block-title {
  @include section-header-title();
  @media screen and (max-width: 580px) {
    h2{
      font-size: 15px;
      font-weight: 600;
    }
  }
}
.cc-client-container,
#app {
  background-color: $white-color;
  //ipad
  @media all and (device-width: 768px) and (device-height: 1024px) 
   {
    width: 164%;
  }
  //ipad pro
  @media all and (device-width: 320px) and (device-height: 568px) 
   {
    width: 108%;
  }
  @media all and (device-width: 1024px) and (device-height: 1366px)  {
    width: 164%;
  }
  //galaxy fold
  @media all and (device-width: 280px) and (device-height: 653px) 
  {
    width: 125%;
 }
}
.container-fluid {
  padding-left: 45px;
  padding-right: 45px;
  @media screen and (max-width: 580px)
  {
    padding-left: 2px;
    padding-right: 22px; 
    padding-bottom: 32px;
  }
  .my-5 
  {
    @media screen and (max-width: 580px)
    {
    padding-top: 40px;
    margin-top: 0rem!important;
    margin-bottom: 0rem!important;
    }
  }
  .container-fluid-search
  {
    width: 40%;
    margin-Left: 100px;
  }
  .container-fluid-logo-mobile
  {
    display: none;
  }
  .ant-tabs-tab-next-icon{
  color:white;
}
.ant-tabs-tab-prev-icon{
  color:white;
}
.solution-tab
{
  .section-title{
  @media screen and (max-width: 580px)
  {
    font-size:30px;
  }
}
}
.job-card
{
  .ant-collapse
  {
    @media screen and (max-width: 580px)
  {
      font-size: 10pt;
      white-space: nowrap;
  }
}
}
/*ant accordation for mobile*/
.ant-collapse 
{
  @media screen and (max-width: 580px)
  {
      font-size: 15pt;
      font-weight: 400;
      background-color: white;
      font-family: poppins,sans-serif;
      //white-space: nowrap;
      //border: 1px;
      .ant-collapse-item
      {
      //border-bottom: 1px solid #4B426B;
      .ant-collapse-header
      {
        
        color: #4B426B;
        .anticon-right
          {
            font-size: 25px
          }
      }
  
      }
  
  }
}
}
.panel-accordian-header
{
  @media screen and (max-width: 580px)
  {
    font-size:25px;
    font-weight: 600;
    font-family: 'Poppins', sans-serif;
    color:#4B426B;
  }
}
.searchheader
      {
        background-image: url('~/assets/images/Shenzyn_whitelogo.png') !important;
        background-repeat: no-repeat !important;
        position: absolute;
        width: 176px;
        height: 49px;
        top: 10px;
        left: 3px;
      }
.container-fluid-mobile
{
 
  .mobile-container{
    display: none;
    .ant-menu >.ant-menu-item-divider
        {
            border-bottom: 1px solid #707070; 
        }
        .ant-menu > .ant-menu-item-divider
        {
          height: 0.5px;
        }
        .ant-menu-inline .ant-menu-item::after
        {
          border-right: 3px solid #694B94;
        }
  }
  @media screen and (max-width: 580px) {
    .web-container{
      display: none;
    }
    .mobile-container{
      display: block;
      .nav-text
      {
        .headericon-left
        {
          margin-top: -6px;
          margin-left: -27px;
          margin-right: -9px;
          .anticon-left
          {
            font-size: 26px;
          }
        }
       font-family: 'Poppins', sans-serif;
        @media screen and (max-width: 580px)
       {
         font-size:22px;
         color:#4B426B;
         font-weight:600;
         a{
           color:#965C89;
         }
        
        .menu-divider{
          background-color:#707070;
          margin:-1px 0;
          height:3px;
          .ant-menu-item-divider{
            height:2px;
          }
        }
        
         
        
      }
      }
    @media screen and (max-width: 580px)
      {
      .ant-menu-item-selected
      {
        .nav-text
        {
          font-size:25px;
        }
      }
      .ant-menu:not(.ant-menu-horizontal) .ant-menu-item-selected
      {
      background-color: white;
      }
    }
    .login-menu
    {
      color:#4B426B;
      font-size:15pt;
      font-family: 'Poppins', sans-serif;
      font-weight:normal;
    a
    {
      color:#4B426B;
      font-size:15pt;
      font-family: 'Poppins', sans-serif;
      font-weight:normal;
    }
  }
    .ant-menu-submenu-open > div:first-child > span:first-child{
        .menu-open {
          color: red;
        }
      }
    
    }
    .navbar-nav
    {
      background-color: white;
      height: 563px;
    }
    
    .nav-item
    {
      display: flex;
      svg
      {
        height: 49px;
        color: #422668;
      }
    }
    @media screen and (max-width: 580px) {
      .reverse-layout
      {
        flex-direction: column-reverse !important;
      }
    }
   
}
.container-fluid-search
{
  @media screen and (max-width: 580px) {
    width: 72%;
    margin-Left: 12px;
  }
  @media all and (device-width: 280px) and (device-height: 653px) 
  {
    width: 67%;
    margin-Left: 12px;
 }
}
.container-fluid-logo
{
  @media screen and (max-width: 580px) {
  display: none;
  }
}
.container-fluid-logo-mobile
{
  @media screen and (max-width: 580px) {
  display: block;
  }
}
@media screen and (max-width: 580px) {
  padding-left: 15px;
  padding-right: 15px;
}  
@media (max-width: 767.98px)
  {
    height: 70px;
    background: linear-gradient(
      90deg
      ,#965C89,#694B94 );
          border-radius: 0px 0px 16px 15px;
          filter: drop-shadow(0px 3px 6px #00000029);
      }
      
      
      
     // background-image:url("/images/Shenzyn_whitelogo.png") ;
  }
@media screen and (max-width: 1366px) and (min-width: 1100px) {
  .joblist-carousel {
    margin-top: 90px;
  }
}
@media screen and (max-width: 1100px) and (min-width: 999px) {
  .joblist-carousel {
    margin-top: 90px;
  }
}
html {
  scroll-padding-top: 100px; /* height of sticky header */
}
body {
  background-color: #ffffff;
  height: 100%;
  @include custom-scrollbar();
}
.app-header {
  box-shadow: 0px 7px 15px #00000014;
  z-index: 999;
}
.app-header-inner .list-inline-item .list-icon {
  font-size: 1rem;
  line-height: 100px;
  padding: 0 4px;
  display: none;
  @media screen and (max-width: 580px) {
    display: inline-block;
  }
}

*,
*::before,
*::after {
  box-sizing: border-box;
}
.ant-input:focus {
  border-color: transparent;
  border-right-width: 1px !important;
  outline: 0;
  box-shadow: 0 0 0 2px rgba(223, 73, 78, 0.2);
}
/*****  HEADER *******/
// .ant-btn-primary {
//   background: transparent linear-gradient(107deg, #b55f8b 0%, #6c4099 100%) 0% 0% no-repeat padding-box;
//   border-color: transparent;
//   &:hover {
//     background: transparent linear-gradient(107deg, #b55f8b 0%, #6c4099 100%) 0% 0% no-repeat padding-box;
//     border-color: transparent;
//   }
// }
.ant-spin-spinning {
  display: flex;
  justify-content: center;
  align-items: center;
}
.navbar-expand-lg .navbar-collapse {
  display: flex !important;
  flex-basis: auto;
  flex-direction: row-reverse;
}
.navbar-brand {
  margin-left: 5px;
  img {
    height: 38px;
  }
}
/******************** New css for header ***************************/
.navbar {
  padding: 0.4rem 0;
  .navbar-nav{
        a,.header-nav{
          padding-bottom: 8px;
           @include navbarSize
          }
        :hover{
          color: #aa5b8c;

        }
        .active{
          border-bottom: 2px solid #EF3C50;
          .menu-angle-down
          {
            display: none;
          }
        }
        .menu-angle-down
        {
          height: 49px;
          right: 32px;
          position: absolute;
        }
        
  }

}
.ant-dropdown {
  .login-dropdown{
    width: 135px;
    left: 55px;
    // margin-right: -60px;
    border-radius: 0px 0px 0px 25px;
    padding-left: 5px;
    
    .ant-dropdown-menu-item-group-title{
      padding: 0px;
    }
    .ant-dropdown-menu-submenu-arrow{
      display: none;
    }
    .ant-dropdown-menu-submenu-title,.ant-dropdown-menu-item > a{
      @include navbarSize
    }
    .ant-dropdown-menu-item > a:hover,.ant-dropdown-menu-submenu-title:hover{
     color: #b55f8b;
    }
   
  }
  
}

.ant-dropdown-menu-item #home_login_hiring_partner,.ant-dropdown-menu-item #home_login_training_partner{
  @include navbarSize
}
.ant-dropdown-menu-item #home_login_hiring_partner:hover,.ant-dropdown-menu-item #home_login_training_partner:hover{
  color: #b55f8b;
  
 }
.ant-dropdown-menu-submenu-popup > .ant-dropdown-menu {
  width: 160px;
  height: 68px;
  border-radius: 0px 0px 0px 25px;
  padding-left: 10px;
  margin-right: 11px;
  
}

// .dropdown-menu {
//   font-size: 0.9rem;
//   border: none;
//   box-shadow: 0 0.15rem 1.75rem 0 rgba(33, 40, 50, 0.15);
//   position: absolute;
//   top: 60px;
//   left: -44px;
// }

// .dropdown-menu .dropdown-header {
//   font-size: 0.75rem;
//   font-weight: 700;
//   display: flex;
//   align-items: center;
// }

// .dropdown-menu .dropdown-item {
//   display: flex;
//   align-items: center;
// }

// .dropdown-menu .dropdown-item .dropdown-item-icon {
//   margin-right: 0.5rem;
//   line-height: 1;
// }

// .dropdown-menu .dropdown-item .dropdown-item-icon svg {
//   height: 0.9em;
//   width: 0.9em;
// }

// .dropdown-menu .dropdown-item.active .dropdown-item-icon,
// .dropdown-menu .dropdown-item:active .dropdown-item-icon {
//   color: #fff;
// }

// .dropdown .dropdown-toggle {
//   display: inline-flex;
//   align-items: center;
// }

// .dropdown .dropdown-toggle .dropdown-arrow {
//   margin-left: 0.4rem;
//   margin-right: 0;
//   transition: transform 0.1s ease-in-out;
//   font-size: 0.6em;
// }

// .dropdown.show .dropdown-toggle .dropdown-arrow {
//   transform: rotate(90deg);
// }
// .dropdown-item:focus,
// .dropdown-item:hover {
//   color: #171c23;
//   text-decoration: none;
//   background-color: #f2f6fc;
// }
.custom-toggler.navbar-toggler {
  border-color: yellow !important;
  /*display:none;*/
}
.custom-toggler .navbar-toggler-icon {
  background-image: url(
"data:image/svg+xml;charset=utf8,%3Csvg viewBox='0 0 30 30' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath stroke='rgba(255, 255, 255, 255)' stroke-width='3' stroke-linecap='round' stroke-miterlimit='10' d='M4 8h24M4 16h24M4 24h24'/%3E%3C/svg%3E");
}
.bg-header {
  // background-color: #f8f9fa !important;
  height: 80px;
  // box-shadow: 0px 7px 15px #00000014;
  z-index: 15;
  @include bg-white;
  @media screen and (max-width: 580px) {
    height: auto !important;
    margin-top: -7px;
  }
}

.equip-header {
  background-color: #fff !important;
  font-family: "Nunito", sans-serif;
  // height: 100px;
  background: #ffffff 0% 0% no-repeat padding-box;
  align-items: center;

  a {
    color: #303030;
  }
}
// COOKIE
.cookie__container {
  // width: 100%;
  text-align: center;
}
.equip-hero {
  background-color: #fff !important;

  .banner-subscribe {
    margin-top: 50px;
    input[type="email"] {
      background: #ffffff 0% 0% no-repeat padding-box;
      box-shadow: 0px 15px 33px #2e3b565c;
      border-radius: 5px;
      height: 55px;
      width: 250px;
      padding: 15px;
      outline: none;
      border: none;
      border-bottom-right-radius: 0;
      border-top-right-radius: 0;
      color: #cbcbcb;
      // @media (min-width: 768px) {
      //   margin-bottom: 0px;
      //   width: 75%;
      //   border-right-width: 0px;
      // }

      &:focus {
        color: #cbcbcb;
      }
    }

    input[type="submit"] {
      background: #494368 0% 0% no-repeat padding-box;
      border-radius: 0px 15px 15px 0px;
      background-color: #494368;
      color: #fff;
      padding: 0 20px;
      cursor: pointer;
      border-radius: 5px;
      height: 55px;
      outline: none;
      border: none;
      border-top-left-radius: 0;
      border-bottom-left-radius: 0;
      // @media (min-width: 768px) {
      //   width: 25%;
      // }

      // &:hover {
      //   color: #000;
      // }
    }
  }
}
.ant-divider-horizontal {
  margin: 16px 0;
}
.equip-hero-text {
  margin-top: 100px;
  font-family: "Nunito", sans-serif;
  margin-left: 60px;
  text-align: left;
  // margin-bottom: 40px;
  h1 {
    color: #f35f7b;
    font-weight: 800;
    font-size: 48px;
  }
  h3 {
    color: #303030;
    margin-top: 40px;
    font-weight: 500;
    font-size: 20px;
  }
}
.equip-features-container {
  background: #fff;
  font-family: "Nunito", sans-serif;
  .block-title {
    display: flex;
    justify-content: center;
    margin-bottom: 40px;
  }
  h2 {
    color: #303030;
    font-size: 36px;
    font-weight: 700;
  }
  .icon-card-v1 {
    padding: 30px;
  }
  .icon--circle {
    background: #61cdd8 0% 0% no-repeat padding-box;
    width: 80px;
    height: 80px;
  }
  .icon-card__header {
    color: #f35f7b;
    font-weight: 700;
    font-size: 20px;
    text-transform: capitalize;
  }
}

.equip-howitworks-container {
  background: #fff;
  font-family: "Nunito", sans-serif;
  padding: 60px 0;
  .block-title {
    display: flex;
    justify-content: center;
    margin-bottom: 40px;
  }
  h2 {
    color: #303030;
    font-size: 36px;
    font-weight: 700;
  }
  .video-wrapper {
    display: flex;
    justify-content: center;
    height: 641px;
    background: #303030 0% 0% no-repeat padding-box;
    border: 1px solid #707070;
  }
}
.equip-getequipped-container {
  background: #494368;
  font-family: "Nunito", sans-serif;
  padding: 60px 0;
  .equip-getequipped-wrapper {
    width: 70%;
  }
  .equip-getequipped-subtitle {
    text-align: center;
    font-size: 28px;
    letter-spacing: 0px;
    color: #ffffff;
    margin: 20px 0;
  }
  .equip-getequipped-title {
    text-align: center;
    font-size: 48px;
    font-weight: 700;
    letter-spacing: 0px;
    color: #ffe66d;
  }
}
.equip-whocanuseequip-container {
  background: #fff;
  font-family: "Nunito", sans-serif;
  padding: 60px 0;
  .block-title {
    display: flex;
    justify-content: center;
    margin-bottom: 40px;
  }
  h2 {
    color: #303030;
    font-size: 36px;
    font-weight: 700;
  }
}

.demo-btn {
  background: #61cdd8;
  color: #fff !important;
  border-radius: 25px;
  width: 135px;
  height: 44px;
  font-size: 14px;
  text-align: center;
  font-weight: 500;
  &:hover {
    background: #61cdd8;
    color: #fff !important;
  }
}

.nav-item {
  margin: 5px 7px;
  @media screen and (max-width: 580px) {
  border-bottom: 2px solid;
  padding-bottom: 15px;
  color:#707070;
  }
  // .ant-btn:hover {
  //   background: transparent linear-gradient(250deg, #68439a 0%, #aa5b8c 100%) 0% 0% no-repeat padding-box;
  //   color: #ffffff;
  // }
  // .ant-input:hover {
  //   // border-color: 1px solid #d9d9d9;
  //   border-color: #d9d9d9 !important;
  // }
}
// .ant-btn:hover,
// .ant-btn:focus {
//   color: #fff !important;
//   border-color: transparent !important;
// }
// .nav-link {
//   color: #68439a;
//   font-weight: 600;
//   font-size: 14px;
// }

// .webinar-btn {
//   background: transparent linear-gradient(250deg, #68439a 0%, #aa5b8c 100%) 0% 0% no-repeat padding-box;
//   color: #fff !important;
//   border-radius: 25px;
//   width: 120px;
//   height: 48px;
//   font-size: 14px;
//   text-align: center;
//   font-weight: 600;
// }
// .webinar-btn:active {
//   color: black !important;
// }
// .webinar-btn:focus,
// .webinar-btn:hover {
//   color: #fff !important;
//   background: transparent linear-gradient(250deg, #68439a 0%, #aa5b8c 100%) 0% 0% no-repeat padding-box;
// }

// .search-btn {
//   background: transparent linear-gradient(250deg, #68439a 0%, #aa5b8c 100%) 0% 0% no-repeat padding-box;
//   border: none;
//   // border-radius: 12px;
//   border-radius: 34px;
//   width: 75px;
//   height: 48px;
//   padding-bottom: 5px;
//   width: 60px;
//   &::hover {
//     background: transparent linear-gradient(250deg, #68439a 0%, #aa5b8c 100%) 0% 0% no-repeat padding-box !important;
//     border: none !important;
//   }
// }
.search-icon{
  font-size: 24px;
  color: $new-primary-color;
  padding: 9px 13px 9px 25px;
  border-radius:34px;
  margin-right: 8px;
  width: 100%; 
  @media screen and (max-width: 580px) {
    font-size: 28px;
    color:#ffffff !important;
    padding: 8px 0px 3px 2px;
    border-radius: 34px;
    margin-right: -8px;
    width: 100%;
  }
}
.search-icon:hover{
  color: #aa5b8c;
}
.ant-input-affix-wrapper .ant-input-suffix {
  right: -5px;
}
// .ant-input {
//   width: ;
// }
.ant-input-affix-wrapper .ant-input:not(:last-child) {
  
  border-radius: 34px;
  // width: 327px;
  height: 48px;
  border-color: $new-primary-color;
  border-width: 2px;
  color: black;
  @media screen and (max-width: 580px) {
  border-color: white;
  background: linear-gradient(
   90deg, #965C89, #694B94);
  }
}

/*****  HEADER ENDS *******/

/***** 
       UTILITIES CLASSES
             *******/

a:hover {
  text-decoration: none;
}
.widgets {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  text-align: left;
  // margin: 10px;
  // padding: 20px;
  .widget-badge {
    top: 0;
    left: 0;
    p {
      font-size: 10px;
      padding: 2px 8px;
      font-weight: 600;
      font-family: "Lato", sans-serif;
      color: #a96091;
      width: 100px;
      margin-bottom: 10px;
      left: 0;
      background: #fff;
    }
  }
  .ant-progress-show-info .ant-progress-inner {
    border-radius: 0 !important ;
    height: 18px;
  }
  .ant-progress-bg {
    // position: relative;
    background-color: #ffd0da;
    height: 18px !important;
    border-radius: 0;
  }
  .ant-progress-text {
    color: #fff;
  }
  .polls-widget {
    @include widget;
    background: #d95b76 0% 0% no-repeat padding-box;
    box-shadow: 0px 0px 10px #d95b76b3;
    color: #fff;
    height: 366px;
    .poll-question {
      font-size: 14px;
      max-width: 100%;
    }
    .poll-question {
      font-size: 18px;
    }
    .poll-options {
      font-size: 12px;
      position: relative;
      // can be commneted out
      // color: #1d1d1d;
      // bottom: -25px !important;
      // z-index: 2;
    }
  }
  .jobs-widget {
    @include widget;
    // width: 268px;
    display: flex;
    height: 170px;
    background: #66449b 0% 0% no-repeat padding-box;
    box-shadow: 0px 0px 10px #66449bb3;
    display: block;
    cursor: pointer;
    .jobs-count {
      flex-direction: column;
      font-size: 65px;
      margin-bottom: 0;
    }
    .count-box-container {
      display: flex;
      justify-content: space-between;
      margin-bottom: 10px;
    }
    .job-positions {
      font-size: 24px;
      flex-direction: column;

    }
    .count-wrapper {
      flex-direction: column;
    }
    .company-logo {
      height: 80px;
      width: 80px;
      padding: 10px;
      background: #ffffff;
      // border: 1px green solid;
      img {
        height: 55px;
        width: 100%;
      }
    }
  }

  .webinar-error-title {
    margin-left: 20px;
    margin-top: 20px;
  }

  .webinar-past-btn {
    width: 150px;
    height: 30px;
    font-size: 14px;
    border-radius: 0;
    padding: 4px;
    text-align: center;
    background: #ffffff 0% 0% no-repeat padding-box;
    color: #d95b76;
    margin-left: 20px;
    margin-top: 25px;
    &:hover {
      color: #d95b76 !important;
    }
  }
  .webinars-widget {
    @include widget;
    padding: 10px 0;
    // width: 273px;
    height: 189px;
    background: #d95b76 0% 0% no-repeat padding-box;
    box-shadow: 0px 0px 10px #d95b76b3;
    display: flex;

    .webinar-image-col {
      width: 30%;
      padding: 8px;
      .webinar-reg-btn {
        width: 80px;
        height: 25px;
        font-size: 10px;
        border-radius: 0;
        padding: 4px;
        text-align: center;
        background: #ffffff 0% 0% no-repeat padding-box;
        color: #d95b76;
        margin-top: 25px;
        &:hover {
          color: #d95b76 !important;
        }
      }
    }
    .webinar-description-col {
      width: 70%;
      padding: 5px;
      .webinar-text-presenter {
        font-size: 16px;
        font-weight: 700;
      }
      .webinar-date-time {
        font-size: 12px;
      }
      .webinar-text-title {
        font-size: 16px;
        font-weight: 500;
        height: 45px;
        display: -webkit-box;
        max-width: 100%;
        margin: 0 auto;
        line-height: 1.25;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;
        overflow: hidden;
        text-overflow: ellipsis;
      }
      .webinar-text-presenter-design {
        font-size: 12px;
        font-weight: 500;
        height: 16px;
        overflow: hidden;
      }
      .weninar-text {
        font-size: 13px;
        font-weight: 600;
      }
    }
  }
  .diversity-partners-widget {
    @include widget;
    height: 189px;
    background: #a96091 0% 0% no-repeat padding-box;
    box-shadow: 0px 0px 10px #a96091b3;
    cursor: pointer;
    .partner-widget-inner-wrapper {
      display: flex;
      justify-content: flex-start;
      .company-name {
        font-size: 18px;
      }
      .company-location {
        font-size: 12px;
      }
      .company-info-wrapper {
        display: flex;
        justify-content: center;
        flex-direction: column;
      }
      .company-logo {
        height: 120px;
        width: 120px;
        background: #ffffff;
        display: flex;
        padding: 20px;
        align-items: center;
        margin: 0 10px 0 0;
        img {
          height: 60px;
          width: 100%;
        }
      }
    }
  }
  .blogs-widget {
    @include widget;
    // width: 588px;
    height: 341px;
    background: #a96091 0% 0% no-repeat padding-box;
    box-shadow: 0px 0px 10px #a96091b2;
    .blogs-widget-inner-wrapper {
      display: flex;
      justify-content: space-around;
      a {
        color: white;
        text-decoration: none;
      }

      a:hover {
        color: #00a0c6;
        text-decoration: none;
        cursor: pointer;
      }
      .read-more {
        font-size: 12px;
        cursor: pointer;
        text-decoration: underline;
      }
      .blog-text {
        display: flex;
        justify-content: center;
        flex-direction: column;
        width: 50%;
        overflow: hidden;
        .blog-intro-text {
          font-size: 14px;
          height: 80px;
          overflow: hidden;
        }
        // .author-info {
        //   flex-direction: column;
        h3 {
          margin: 2px;
          font-size: 16px;
        }
        h5 {
          margin: 5px;
          font-size: 12px;
          font-weight: 700;
        }

        // }
      }
      .blog-image {
        // height: 120px;
        width: 50%;
        padding: 0 10px 0 0;
        // margin: 10px 20px;
        // background: #ffffff;
        display: flex;
        align-items: center;
        img {
          // height: 80px;
          width: 100%;
        }
      }
    }
  }
  // .ads-widget {
  //   @include widget;
  //   width: 272px;
  //   height: 170px;
  //   background: #66449b 0% 0% no-repeat padding-box;
  //   box-shadow: 0px 0px 10px #66449bb3;
  // }
  .ads-widget {
    @include widget;
    // width: 268px;
    display: flex;
    height: 170px;
    background: #66449b 0% 0% no-repeat padding-box;
    box-shadow: 0px 0px 10px #66449bb3;
    display: block;
    cursor: pointer;
    .jobs-count {
      flex-direction: column;
      font-size: 65px;
      margin-bottom: 0;
    }
    .count-box-container {
      display: flex;
      justify-content: space-between;
      margin-bottom: 10px;
    }
    .job-positions {
      font-size: 24x;
      flex-direction: column;
    }
    .count-wrapper {
      flex-direction: column;
    }
    .company-logo {
      height: 60px;
      width: 225px;
      padding: 10px;
      background: #ffffff;
      // border: 1px green solid;
      img {
        height: 35px;
        width: 100%;
      }
    }
  }
  .instagram-widget {
    @include widget;
    // width: 273px;
    padding: 0;
    // height: 366px;
    // background: #d95b76 0% 0% no-repeat padding-box;
    // box-shadow: 0px 0px 10px #d95b76b3;
    display: flex;
    justify-content: center;
    align-items: center;
    background: #ffffff;
    box-shadow: 0px 7px 15px #00000014;
    iframe {
      border: none;
      min-width: initial;
      // width: 250px;
      // max-width: 300px;

      iframe#instagram-embed-0 {
        min-width: 200px !important;
      }

      iframe#instagram-embed-0 {
        min-width: 200px !important;
      }
    }
  }
}
// .instagram-media {
//   min-width: 300px !important;
// }

iframe#instagram-embed-0 {
  min-width: 200px !important;
}
iframe#instagram-embed-0 {
  width: 300px;
}
.joblist-carousel {
  background: #fff;
  .swiper-button-next,
  .swiper-container-rtl .swiper-button-prev {
    right: 50px;
    left: auto;
  }
  .swiper-button-prev,
  .swiper-container-rtl .swiper-button-next {
    left: 50px;
    right: auto;
  }

  @media screen and (min-width: 360px) {
    /*.swiper-container {
      //width: 360px; need to rework
    }*/
    .swiper-wrapper
    {
      margin-left: 10px;
    }
  }

  @media screen and (min-width: 768px) {
    .swiper-container {
      width: 1220px;
    }
  }
}
.text-center{
  .text-left{
    .job-positions{
      @media screen and (min-width: 768px){
        margin-left:20px;
      }
    
    }
  }
}
.section-title {
  color:$new-primary-color;
  font-weight: 800;
  font-size: 48px;
  margin-bottom: 1.4rem;
  font-family:'Poppins', sans-serif;
}
.solution-title{
  margin-left:46px;
 // margin-top: 35px;
}

/*.featured-title
{
  margin-top: 60px;
}*/
/***** UTILITIES CLASSES ENDS *******/

/*****  
      FEATURED SECTION *******/
.shenzyn-products-container {
  background: transparent linear-gradient(250deg, #68439a 0%, #aa5b8c 100%) 0% 0% no-repeat padding-box;
  padding: 40px 0;
  color: #fff;
  min-height: 642px;

  .product-title-para {
    font-size: 24px;
    color: #fff;
    font-weight: 500;
    font-family: "Lato";
    padding: 20px 0;
    text-align: left;
    width: 90%;
    text-align: justify;
  }
  .product-descriptive-para {
    font-size: 16px;
    color: #fff;
    font-weight: 500;
    width: 90%;
    text-align: justify;
    font-family: "Lato";
    // margin: 30px 0;
    // padding: 20px 0;
    .wobitems
    {
      display: flex;
      h3 {
        font-weight: bold;
        font-size:16px;
        letter-spacing: 2px;
      }
      
      div {
        font-size: 16px;
        margin-top: -4px;
      }
    }
  }
  .born-image
    {
     img
    {
      max-height:450px;
      margin-top: -55px;
      @media screen and (max-width: 768px) {
        max-height:169px;
        margin-top: auto;
      }
      @media screen and (max-width: 1366px) and (min-width: 769px) 
      {
        max-height:301px;
        margin-top: auto;
      }
      /*Galaxy fold*/
      @media screen and (max-width: 280px) and (min-width: 280px) 
      {
        max-height:130px;
        margin-top: auto;
      }
    }
    
   
  }
  .born-btn
    {
      margin-left: -5px;
      margin-top: 6px;
    }
  .ant-tabs-bar {
    // margin: 0 0 16px 0;
    border-bottom: transparent;
  }
  .ant-tabs-tab {
    color: #c7c7c7;
    font-weight: 600;
    font-size: 20px;
  }
  .ant-tabs-nav .ant-tabs-tab-active {
    color: #fff;
    font-weight: 800;
    font-size: 34px;
    padding: 12px 16px 12px 0;
  }
  .ant-tabs-nav .ant-tabs-tab:hover {
    color: #ffffff;
  }
  .ant-tabs-ink-bar {
    position: absolute;
    bottom: 10px;
    left: 0;
    z-index: 1;
    box-sizing: border-box;
    width: 0;
    height: 4px;
    width: 21% !important;
    border-radius: 50px;
    background-color: #119da4;
    -webkit-transform-origin: 0 0;
    transform-origin: 0 0;
  }
  .theme-btn {
    width: 231px;
    height: 63px;
    background: #ffffff 0% 0% no-repeat padding-box;
    border-radius: 32px;
    font-size: 20px;
    color: #6e4598 !important;

    &:hover {
      border-color: transparent;
      color: #68439a !important;
    }
  }
}

.featured-container {
  min-height: 500px;
  margin: 0;
  padding: 0;
  @include bg-white();
  p {
    font-size: 18px;
    line-height: 22px;
    max-width: 75%;
  }
  .dp-image {
    height: 400px;
    background-color: #fff;
  }
}
/*****  FEATURED SECTION ENDS *******/

/***** 
       FOOTER SECTION 
                      *******/
.footer-wrapper {
  margin-top: 80px;
  height: 189px;
  // position: relative;
  padding: 20px 0;
  background: #C4C2C2 0% 0% no-repeat padding-box;
  display: flex;
  align-items: center;
  justify-content: center;
  @media screen and (max-width: 580px) {
    //height: auto;
    height:142px;
    margin-top: 0px;
  }
  .footer-link {
    text-decoration: none;
    font-size: 13px;
    text-transform: lowercase;
    color: $new-primary-color;
    @media screen and (max-width: 580px)
    {
      float:left;
      text-transform: lowercase;
      font-size: 11px;
      text-align: center;
      color:#000000;
      margin-right:150px;
      
      
    }
  }
  .footer-links li {
    list-style: none;
    color: #66439a;
    font-size: 14px;
    padding: 5px 10px;
    // font-weight: 600;
    
  }
  .footer-logo
  {
    width: 200px;
    margin-top: 11px;
  }
  .footer-box p{
    font-size: 15px;
    color: $new-primary-color;
    font-weight: normal;
    font-family: 'Poppins', sans-serif;
    @media screen and (max-width: 580px) 
    {
      font-size: 15px;
      color: #000000;
      text-align: center;
      font-weight: 100px;
      float:right;
      font-family: 'Poppins', sans-serif;
    }
  }
  .footer-logo-mobile
  {
    width: 149px;
    margin-top: -15px;
    @media only screen and (max-device-width: 480px) {
      width: 134px;
    }
   .footer-link 
     {
       font-size:11.4px;
       color:#000000;
       font-family: 'Poppins', sans-serif;
       font-weight:normal;
       @media only screen and (max-device-width: 480px) {
        font-size:9.8px;
      }
     }
  }
  a {
    color: $new-primary-color;
    font-size: 17px;
    text-decoration: none;
    text-transform: capitalize;
    font-family: 'Poppins', sans-serif;
    // font-weight: 600;
  }
  .footer-social-list li{
    // margin-top: 5px;
    margin-right: 5px !important;
  }
  .footer-social-icons {
    font-size: 23px;
    opacity: 1;
  }
  /*
  .footer-social-list
  {
   
    @media only screen and (min-device-width : 375px) and (max-device-width : 667px)  {
    margin-left: 58px !important;
  }
  @media only screen and (min-device-width : 411px) and (max-device-width : 731px)  {
    margin-left: 97px !important;
  }
  @media only screen and (min-device-width : 320px) and (max-device-width :667px)  {
    margin-left: 28px !important;
  }
  @media only screen and (max-device-width: 480px) {
    margin-left: 28px !important ;
  }
  @media only screen and (max-device-width: 360px) and (max-device-width :640px) {
    margin-left: 58px !important;
  }

}  
*/
    
}

/***** FOOTER SECTION ENDS *******/

// THEME COLOR
// .ant-btn:hover,
// .ant-btn:focus {
//   color: #cb3438;
//   background-color: #fff;
//   border-color: #cb3438;
// }

// Home Page
.theme-underline {
  width: 70%;
  color: #119da4;
}

.hero {
  background: #fff;
  height: 85vh;
  @media screen and (max-width: 580px) {
    height: auto;
  }
  .hero-text {
    margin-top: 200px;
    text-align: left;
    @media screen and (max-width: 580px) {
        margin-top:15px;
        margin-bottom:30px;
        text-align: center;
    }
    h1 {
      color: #422668;
      font-family: 'Poppins', sans-serif;
      font-weight: 600;
      font-size: 37px;
      line-height: 1;
      @media screen and (max-width: 580px) {
        height: 19px;
       // width: 262px;
        font-size: 20px;
        font-family: Helvetica, sans-serif;
        font-weight: bold;
        color: #422668;
        line-height: 24px;
        text-align: center;
    }
      
    }
    h2 {
      color: #000000;
      font-family: 'Poppins', sans-serif;
      font-weight: 600;
      font-size: 30px;
      // margin-left: 50px;
    @media screen and (max-width: 580px) {
     // width: 243px;
      height: 18px;
      font-size: 15px;
      color: #000000;
      text-align: center;
      line-height: 18px;
      font-family: Helvetica, sans-serif;
      }
    }
    p {
      font-size: 15px;
      font-family: 'Poppins', sans-serif;
      color: #000000;
      width:100% !important;
      // text-align: left;
      margin-bottom: 25px;
      @media screen and (max-width: 580px) {
        width: 247pt;
        height: 84pt;
        font-size: 10pt;
        text-align: center;
        line-height: 16px;
        color: #000000;
        font-weight:normal;
        font-family:poppins, sans-serif;
      }
      @media  screen and (min-width:393px) {
        width: 272pt;
       
      }
    }
  }

  .register-btn {
    font-family: 'Poppins', sans-serif;
    background: transparent linear-gradient(90deg, #965C89 0%, #694B94 100%) 0% 0% no-repeat padding-box;
    color: #FFFFFF;
    height: 43px;
    border-radius: 10px;
    width: 140px;
    font-weight: 600;
    font-size: 16px;
    @media screen and (max-width: 580px) {
      //margin-left: 103px;
      font-size: 16pt;
     // width: 144pt;
      height: 32pt;
      border-radius: 12pt;
      font-family: poppins, sans-serif;
      font-weight:normal;
      width: auto;
    } 

  }
  .hero-image {
    right: 0;
    top: 0;
    height: 500px;
    float: right;
    margin-top: 40px;
    margin-right: -45px;
  }
}
.campaign-modal {
  .ant-modal-close {
    right: 0.5rem;
    top: 0.5rem;
    border-radius: 50%;
    background-color: rgba(0, 0, 0, 0.3);
    color: $gray-200;
  }
  .ant-modal-close-x {
    width: 1.5rem;
    height: 1.5rem;
    line-height: 1.5rem;
    font-size: $font-size-sm;
    opacity: $text-inactive;
    transition: opacity 0.3s;
    &:hover {
      opacity: 1;
    }
  }
  .ant-form-item label {
    align-items: baseline;
    display: flex;
  }
}
// DBS CAMPAIGN MODAL STARTS HERE
.dbs-campaign-modal {
  .ant-modal-close {
    right: 0.5rem;
    top: 0.5rem;
    border-radius: 50%;
    background-color: rgba(0, 0, 0, 0.2);
    color: $white-color;
  }
  .ant-modal-close-x {
    width: 1.5rem;
    height: 1.5rem;
    line-height: 1.5rem;
    font-size: $font-size-sm;
    opacity: $text-inactive;
    transition: opacity 0.3s;
    &:hover {
      opacity: 1;
      background-color: lightcoral;
      border-radius: 25px;
    }
  }
  font-family: "Nunito", sans-serif;
  .banner-title {
    font-family: "Lato", "Lucida Sans Regular", "Lucida Grande", "Lucida Sans Unicode", Geneva, Verdana, sans-serif;
    font-weight: 500;
    color: #202124;
  }
  .ant-modal-content {
    border-radius: 0;
    // background: linear-gradient(106deg, #0e1983 0%, #5e13a6 100%) 0% 0% no-repeat padding-box;
    background: $white-color;
    @media (min-width: 360px) and (max-width: 1024px) {
      border-radius: 12px;
    }
  }
  .ant-modal-body {
    padding: 0;
  }
  .dbs-image {
    height: 514px;
    width: 100%;
    @media (min-width: 768px) and (max-width: 1024px) {
      height: 493px;
    }
  }
  .ant-modal-close {
    right: 0.5rem;
    top: 0.5rem;
    border-radius: 50%;
    background-color: rgba(0, 0, 0, 0.2);
    color: $white-color;
  }
  .ant-modal-close-x {
    width: 1.5rem;
    height: 1.5rem;
    line-height: 1.5rem;
    font-size: $font-size-sm;
    opacity: 1;
    transition: opacity 0.3s;
    &:hover {
      opacity: 1;
    }
  }
  .anticon {
    vertical-align: 0;
  }
  .ant-modal-content
  {
    .ant-form-item label {
      align-items: baseline;
      display: flex;
      line-height: 0;
      font-weight: 600;
      color:#040707;
      font-family:poppins,sans-serif;
      font-size: 13.5px;
      
    }
    .ant-form-item-control
    {
      line-height: 0px;
    }
    .ant-form-item
    {
      margin-bottom: 10px;
    }
  }
  
  input[type="text"],
  input[type="password"],
  input[type="number"],
  textarea {
    border-radius: 7px;
  }
  .ant-form-item-label > label {
    color: $black-color;
    font-size: 16px;
  }
  .btn-white-bg {
    border-radius:12px;
    height: 40px;
    font-size: 19px;
    color: #D33E43;
    font-weight:550;
    font-family: poppins,sans-serif;
    left:13%;
    filter: drop-shadow(0px 3px 6px #00000029);
   // background: linear-gradient(106deg, #0e1983 0%, #5e13a6 100%) 0% 0% no-repeat padding-box;
   // border-color: transparent;
    &:hover {
      border-color: transparent;
    }
    @media (min-width: 360px) and (max-width: 1024px) {
      border-radius: 12pt;
      width: 225px;
      height: 41px;
      margin-left: 4px !important;
    }
  }
  .btn-block {
    width:80%;
    margin-left: -9px;
  }
  .form-v1{
    margin-top:40px;
    @media (min-width: 360px) and (max-width: 1024px) {
      margin-top: 1px;
    }
  }
}
.TDC-campaign-modal {
  .TDC-body
  {
    //height: 523px;
    background-image: url('~/assets/designerclass.png');
    background-repeat: no-repeat;
    background-size: cover;
    .sign-up-btn{
      width: 149px;
      height: 36px;
      background: #000000 0% 0% no-repeat padding-box;
      box-shadow: 0px 6px 9px #00000029;
      border-radius: 10px;
      text-align: center;
      font: normal normal 600 20px/30px Poppins;
      letter-spacing: 0px;
      color: #FFFFFF;
      margin-right:85px;
      cursor: pointer;
      margin-top:10px;
    }
    .text{
      color:#000000;
      font: normal normal 300 25px/32px Poppins;
      margin-top:-19px;
    }
    .top_align{
        margin-top:-60px;
        margin-right:20px;
    }
  }
  .ant-modal-body {
    padding: 0px;
    padding-right: 24px;
  }
}
// DBS CAMPAIGN MODAL ENDS HERE
//Myntra Campaign starts here
.myntra-campaign-modal {
  .ant-modal-close {
    right: 0.5rem;
    top: 0.5rem;
    border-radius: 50%;
    background-color: rgba(0, 0, 0, 0.2);
    color: $white-color;
  }
  .ant-modal-close-x {
    width: 1.5rem;
    height: 1.5rem;
    line-height: 1.5rem;
    font-size: $font-size-sm;
    opacity: $text-inactive;
    transition: opacity 0.3s;
    &:hover {
      opacity: 1;
      background-color: lightcoral;
      border-radius: 25px;
    }
  }
 
  .ant-modal-content {
    border-radius: 0;
    // background: linear-gradient(106deg, #0e1983 0%, #5e13a6 100%) 0% 0% no-repeat padding-box;
    background: $white-color;
  }
  .ant-modal-body {
    padding: 0;
  }
  .myntra-image {
    width: 100%;
  }
  .anticon {
    vertical-align: 0;
  }
 
  
}

//Myntra Campiagn model ends here

//TDC campaign
.TDC_image{
  width:auto;
}

.webinar-container {
  padding-bottom: 40px;
  background-color: #ffffff;
  min-height:436px;
  .main-title {
    letter-spacing: 0px;
    color: #4B426B;
    font-size: 22pt;
    opacity: 1;
    font-family: poppins,sans-serif;
    font-weight: 600;
    // margin: 2rem 0;
    @media screen and (max-width: 580px){
    
    }
  }
  .angle_icon
  {
    display:none;
    @media screen and (max-width: 580px){
    display:flex;
    font-size: 18pt;
    font-weight: 600;
    margin-top: -6px;
    a
    {
      color: rgb(150, 92, 137);
      margin-right: 5px;
      opacity: 0.5;
    }
  }
  }
  .ant-tabs-nav .ant-tabs-tab-active
  {
    color:#4B426B;
    font-weight:600;
    font-size:20px;
    @media screen and (max-width: 580px){
      color: #4B426B;
      font-weight: 600;
      font-size: 17px;
    }
  }
  .ant-tabs-nav-container-scrolling
  {
    @media screen and (max-width: 580px){
      padding-right: 0px; 
      padding-left: 0px;
    }
  }
  .ant-tabs-nav .ant-tabs-tab
  {
    @media screen and (max-width: 580px){
      padding:12px 8px;
    }
  }
  .ant-tabs-nav
  {
    color:#4B426B;
    font-size:20px;
    margin-left: 30px;
    @media screen and (max-width: 580px){
      color: #4B426B;
     // font-weight: 600;
      font-size: 17px;
      margin-left: 9px;
    }
  }
  .ant-tabs-nav .ant-tabs-tab:hover
  {
    @media screen and (max-width: 580px){
      color: #4B426B;
     // font-weight: 600;
     
    }
  }
  .ant-tabs-ink-bar 
  {
    background-color:#4B426B;
  }
  .ant-tabs-tab-active ant-tabs-tab
  {
    font-size:30pt;
    font-weight:600;
  }
}
// .job-profile-container {
//   margin-bottom: 40px;
// }

.webinar-title {
  font: normal normal bold 65px/89px Avenir Next;
  color: #a96091;
  font-weight: bold;
  margin-top: 30px;
  margin-left: 70px;
  align-self: flex-start;
  
}
.webinar-search {
  // margin-left: 70px;
  align-self: flex-start;

  .ant-select-selection--single,
  .ant-select-selection--multiple {
    border-radius: 25px;
    // width: 15rem;
    height: 54px;
    margin: 10px auto;
  }

  .ant-select-selection__placeholder {
    // position: absolute;
    color: #a96091;
    // margin-top: 5px;
    font: normal normal 600 23px/28px Lato;
    text-align: center;
    margin-top: -3px;
    height: 28px;
    // top: 41%;
    // height: 28px;
  }
  .ant-select-selection-selected-value {
    margin-top: 10px;
    text-align: center;
    color: #a96091;
    font: normal normal 600 23px/28px Lato;
  }
}

.webinar-tiles {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(371px, 1fr));
  // grid-template-rows: repeat(auto-fit, minmax(371px, 1fr));
  // grid-template-rows: repeat(3, 1fr);
  grid-gap: 65px 70px;
  gap: 40px 40px;
  margin-left: 57px;
  margin-top: 97px;
  // margin-right: 62px;
}
.webinarlist-pagination {
  margin: 20px;
  text-align: center;
  .ant-pagination-prev .ant-pagination-item-link,.ant-pagination-next .ant-pagination-item-link {
    border: none;
    padding-bottom: 3px;
    .anticon-left,.anticon-right{
      font-size: 20px;
      color:$new-primary-color;
    }
  }
  .ant-pagination-item{
    border-color: $new-primary-color;
    border-width: 2px;
    color: $new-primary-color;
  }
  .ant-pagination-item-active{
    background-color:$new-primary-color;
    color: white;
    border-color: $new-primary-color;
  }
}


.webinar-tile {
  background: #ffffff 0% 0% no-repeat padding-box;
    box-shadow: 0px 12pt 15pt #00000029;
    border-radius: 10pt;
    text-align: left;
    padding: 20px;
    margin-top: 30px;
  .title-container {
    height: 60px;
    overflow: hidden;
    // overflow-y: auto;
  }
  .title-container p {
    font-size: 18px;
    line-height: 1.2;
    color: #4B426B;
    height: 75px;
    max-height: 3em !important;
    -webkit-line-clamp: 2 !important;
    -webkit-box-orient: vertical !important;
    overflow: hidden;
    text-overflow: ellipsis;
    font-weight:600;
    font-family:poppins,sans-serif;
    @media screen and (max-width: 580px){
      font-size:12pt;
      font-weight:700;
    }
  }

  .webinar-overview {
    display: flex;
    justify-content: space-between;
  }

  .webinar-details {
    // margin: 0 auto;
    display: flex;
    flex-direction: column;
  }

  .webinar-presenter {
    margin-top: 0px;
    font-family:poppins,sans-serif;
    font-weight: 500;
    letter-spacing: 0px;
    color: #4B426B;
    font-size:18px !important;
    @media screen and (max-width: 580px){
      font-size:13pt !important;
    } 
  }
  .presenter-designation {
    font-family:poppins,sans-serif;
    letter-spacing: 0px;
    color:#4B426B;
    max-width: 211px;
    max-height: 20px;
    overflow-y: hidden;
    font-size:15px !important;
    @media screen and (max-width: 580px){
      font-size:15px !important;
    } 
  }

  .webinar-date,
  .webinar-time {
    // margin-left: 20px;
    margin-top: 5px;
    margin-bottom: 10px;
    font-size: 11px;
    color: #4B426B;
   // font-weight: bold;
    .details-logo {
      padding-right: 10px;
    }
  }

  #register-webinar-button {
    width: 129px;
    height: 36px;
    border-radius: 8px;
    font-size: 18px;
    margin-top: 10px;
    font-family:poppins,sans-serif;
    background: transparent linear-gradient(90deg, #965C89, #694B94);
    color:white;
    filter:drop-shadow(0px,3px,6px,#00000029)
  }

  .webinar-presenter-image {
    width: 125px;
    height: 150px;
    // margin-right: 20px;
    margin-top: 6px;
  }
}

.job-list-item {
  min-width: 265px;
  height: 180px;
  background: #ffffff 0% 0% no-repeat padding-box;
  box-shadow: 0px 0px 15px #00000029;
  border-radius: 20px;
  opacity: 1;
  padding: 10px 25px;
  // background: $theme-gradient;
  box-shadow: 0px 0px 30px #00000015;
  border-radius: 15px;
  margin: 15px;
  border: none;
  border-left-color: #b77bac;
  border-left-style: inset;
  border-left-width: 10px;
  transition: ease-in-out 0.5s;
  -webkit-transition: ease-in-out 0.5s;
  &:hover {
    transform: translateY(-8px);
  }
  @media screen and (max-width: 580px)
  {
  padding-left:-2px;
  padding-right: 37px;
  border-radius: 12px;
  filter: drop-shadow(0px 12px 15px #00000029);
  border-left-color: #d37ae3;
  border-left-style: inset;
  border-left-width: 22px;
  width:88%;
  margin-top: 56px;
  } 
  @media only screen and (min-width:393px)
  {
  width:97%;
  } 
  @media only screen and (min-width:412px)
  {
  width:101%;
  } 
  @media only screen and (min-width:375px)
  {
  width:90%;
  } 

  .job-container-card {
    display: flex;
    justify-content: space-between;
  }
  .job-oppurtunities {
    font-size: 35px;
    color: #3e3e3e;
    text-align: center;
  }
  .sub-text {
    font-size: 15px;
    color: #3e3e3e;
    // border-bottom: 2px solid #119da4;
  }
  .sub-text:after {
    content: "";
    width: 33px;
    display: block;
    margin-top: 10px;
    border-bottom: 2px solid #119da4;
  }
  .text {
    margin-top: 15px;
  }
}

.akwire-container {
  height: 500px;
  background: transparent linear-gradient(66deg, #68439a 0%, #b35f8b 100%) 0% 0% no-repeat padding-box;
  margin: 0;
  padding: 0;
  color: #fff;
  .akwire-title {
    color: #fff;
    font-weight: 800;
    font-size: 48px;
  }
  h3 {
    color: #fff;
  }
  p {
    font-size: 18px;
    line-height: 22px;
    max-width: 75%;
  }
  .theme-btn {
    width: 231px;
    height: 63px;
    background: #ffffff 0% 0% no-repeat padding-box;
    border-radius: 32px;
    font-size: 20px;
    color: #6e4598 !important;

    &:hover {
      border-color: transparent;
      color: #68439a !important;
    }
  }
}
.akwire-image {
  // height: 400px;
  img {
    max-height: 400px;
  }
}
.dp-container {
  min-height: 500px;
  @include bg-white();
  margin: 0;
  padding: 0;
  .company-card-dp {
    width: 100px;
    height: 100px;
    cursor: pointer;
    background: #ffffff 0% 0% no-repeat padding-box;
    box-shadow: 0px 0px 10px #00000029;
    border-radius: 19px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 15px;
    padding: 15px;
    img {
      // height: 45px;
      width: 100%;
    }
  }
  .dp-partner-box {
    height: 400px;
    padding: 20px;
    margin: 10px;
    background-color: #fff;
    display: flex;
    align-items: center;
    justify-content: center;
    overflow: hidden;
    position: relative;
    border-top: transparent linear-gradient(180deg, #ffffff48 0%, #ffffff 100%) 0% 0% no-repeat padding-box;
    .column-1,
    .column-3 {
      flex-grow: 1;
      animation: slide-top 70s infinite linear;
      transition: opacity 0.5s ease-in;
    }
    .column-2,
    .column-4 {
      flex-grow: 1;
      animation: slide-down 70s infinite linear;
      transition: opacity 0.5s ease-in;
    }
    @keyframes slide-top {
      100% {
        transform: translateY(-30%);
      }
      50% {
        transform: translateY(30%);
      }
      // 0% {
      //   transform: translateY(100%);
      // }
    }
    @keyframes slide-down {
      100% {
        transform: translateY(30%);
      }
      50% {
        transform: translateY(-30%);
      }
      // 0% {
      //   transform: translateY(100%);
      // }
    }
  }
  .dp-title {
    color: $new-primary-color;
    font-weight: 800;
    font-size: 48px;
    // border-bottom: #119da4 solid 6px;
  }
  h3 {
    color: #fff;
  }
  p {
    font-size: 18px;
    line-height: 22px;
    font-weight: bold;
    line-height: 1.4;
    max-width: 95%;
    text-align: left;
    margin-left:-14px;
  }
  .theme-btn {
    width: 231px;
    height: 63px;
    background:linear-gradient(90deg,#965C89,#694B94);
    border-radius: 32px;
    font-size: 20px;
    color: #fff !important;
    margin-left:-10px;

    &:hover {
      border: 1px solid #333333;
      color: #fff !important;
    }
  }
}
.know-more-btn {
  margin-top: 80px;
  a {
    font-size: 24px;
    color: #66439a;
    text-decoration: underline;
  }
}
// about us
.shenzyn-about-container {
  .about-text {
    font-size: 18px;
    margin-top: 30px;
  }
  .contactus-image {
    max-height: 450px;
    width: 100%;
  }
  .contact-icon-wrapper {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    margin: 10px;
    h6 {
      text-transform: uppercase;
      font-size: 14px;
      margin-top: 10px;
      font-weight: 600;
      color: #303030;
    }
    p {
      font-size: 13px;
      text-align: center;
    }
  }
  .employee-member-card {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    // padding: 30px;
    // border: 1px solid red;
    .employee-member--profilepic {
      padding: 10px;
      // margin: 10px;
      img {
        height: 200;
        width: 200px;
        border-radius: 50%;
      }
    }
    .employee-member--name-linkedin {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      margin: 0 10px;
      h4 {
        color: #a96091;
        font-size: 18px;
        font-weight: 700;
        font-family: "Lucida Sans", "Lucida Sans Regular", "Lucida Grande", "Lucida Sans Unicode", Geneva, Verdana,
          sans-serif;
      }
      .emp-linkedin {
        font-size: 18px;
        cursor: pointer;
        color: #2f78b7;
        margin: 0 10px;
      }
    }
    .employee-member--title {
      font-style: italic;
      font-size: 14px;
    }
  }
  .ant-tabs-bar {
    // margin: 0 0 16px 0;
    border-bottom: transparent;
  }
  .ant-tabs-tab {
    color: #c7c7c7;
    font-weight: 600;
    font-size: 20px;
  }
  .ant-tabs-nav .ant-tabs-tab-active {
    color: #a96091;
    font-weight: 800;
    font-size: 48px;
    padding: 12px 16px 12px 0;
  }
  .ant-tabs-nav .ant-tabs-tab:hover {
    color: #a96091;
    
  }
  .ant-tabs-ink-bar {
    position: absolute;
    bottom: 10px;
    left: 0;
    z-index: 1;
    box-sizing: border-box;
    width: 0;
    height: 4px;
    width: 28% !important;
    border-radius: 50px;
    background-color: #119da4;
    -webkit-transform-origin: 0 0;
    transform-origin: 0 0;
  }
}
// privacy-policy-terms-conditions

.privacy-policy-terms-conditions {
  .head_text{
    text-align: center;
    margin-top: 70px;
     @media screen and (max-width: 768px) and (min-width: 280px) {
       margin-top:31px ;
       font: normal normal normal 12px/18px Poppins;
       color:#12054D;
     }
   }
  .faq_holder .sort_searchmid1 {
    margin-bottom: 10px;
    // margin-left: 18%;
    margin-right: 30%;
    font-weight: 900;
    font-size: 52px;
    width: 100%;
    @media screen and (max-width: 768px) and (min-width: 280px) 
    {
      font: normal normal 600 25px/38px Poppins;
      color:#422668;
      text-decoration: underline;
    }
   
  }
  .sort_searchmid1 strong {
    float: left;
    width: 100%;
    font-weight: 500;
    font-size: 22px;
    color: #375076;
    margin: 10px 0;
  }
  .sa_head {
    height: 24px;
    padding: 8px 0 0 0;
    position: relative;
    font-size: 22px;
    color: #375076;
  }
  .sub_head_new {
    font-size: 17px;
    padding: 10px 0 0;
    color: #375076;
    @media screen and (max-width: 768px) and (min-width: 280px){
      font: normal normal normal 12px/18px Poppins;
      color:#12054D;
    }

  }
  .ffht {
    width: auto;
    margin-left: 12%;
    margin-right: 12%;
    min-height: 400px;
    @media screen and (max-width: 768px) and (min-width: 280px){
    margin-left: 3%;
    margin-right: 3%;
    }
  }

  @media (max-width: 1180px) {
    .ffht {
      width: auto;
    }
  }

  @media (max-width: 1024px) {
    .ffht {
      width: auto;
    }
  }

  @media (max-width: 1336px) and (min-width: 1320px) {
    .ffht {
      width: auto;
    }
  }
  .para_text{
    @media screen and (max-width: 768px) and (min-width: 280px){
    font: normal normal normal 12px/18px Poppins;
    color:#12054D;
    }
  }
  .text-top{
    @media screen and (max-width: 768px) and (min-width: 280px){
    margin-top:-20%;
    }
  }
  hr{
    @media screen and (max-width: 768px) and (min-width: 280px) 
    {
      border-top:none !important;
    }
  }
}

//Search Page

.job-search-button {
  width: 100%;
  height: 48px;
  
  // margin-top: 10px;
  // border: 1px solid #E5E5E5;
  // .ant-input-search-enter-button input {
  //   border-radius: 27px;
  //   border-top-right-radius: 27px !important;
  //   border-bottom-right-radius: 27px !important;
  //   outline: none;
  // }
  // .ant-input {
  //   height: 50px;
  //   border-radius: 15px;
  //   border-top-right-radius: 27px !important;
  //   border-bottom-right-radius: 27px !important;
  //   margin-top: 10px;
  //   border-color: $new-primary-color;
  // }
  // .ant-input:placeholder-shown {
  //   padding-left: 20px;
  //   height: 54px;
  //   border-radius: 25px;
  //   margin-top: 10px;
  // }
  // .ant-input:hover {
  //   z-index: 0 !important;
  //   // outline: 1px solid #e5e5e5 !important;
  //   border-radius: 15px;
  //   border-color: #d9d9d9 !important;
  // }
  // .ant-input:focus {
  //   z-index: 0 !important;
  //   border-color: transparent !important;
  //   outline: transparent !important;
  //   padding-left: 20px;
  // }
  // opacity: 1;
  // .ant-input-group-addon {
  //   background-color: transparent !important;
  // }
  // .ant-btn-primary {
  //   background: #f1f1f1 linear-gradient(106deg, #b55f8b 0%, #6c4099 100%) 0% 0% no-repeat padding-box;
  //   border-radius: 15px;
  //   // border-top-left-radius: 27px !important;
  //   // border-bottom-left-radius: 27px !important;
  //   width: 203px;
  //   height: 54px;
  //   margin: 0px;
  //   border-color: transparent;
  //   margin-left: -66px;
  //   margin-top: 10px;
  // }
 
    // .ant-input-group{
    //   width: 90%;
    //   margin: auto;
    // }
  .ant-input-group > .ant-input:first-child{
    height: 50px;
   border-radius:30px 0 0 30px ;
    border:2px solid $new-primary-color;
  
  }
  .ant-input-search-icon{
    font-size: 24px;
    color: $new-primary-color;
    padding: 9px 13px 9px 25px;
  border: radius 34px;
  margin-right: 8px;
  width: 100%;

  
  }
  // .ant-btn-primary{
  //   background: transparent linear-gradient(90deg, #965C89 0%, #694B94 100%) 0% 0% no-repeat padding-box;
  //   border:none ;
  //   height: 50px;
  //   border-radius:0 30px 30px 0 ;
  //   padding:0 40px 0 40px ;
   
  // }
  // .ant-btn .anticon{
  //   font-size: 20px;
  // }
}

@media screen and (max-width: 520px) {
  .job-search-button {
    width: auto;
    height: 54px;
  }
}
.title-row {
  margin-top: 50px;

  .filter {
    font: normal normal bold 25px/33px Avenir Next;
    color: $new-primary-color;
    text-align: center;
    margin-top: 3rem;
  }
  .ant-checkbox-group {
    margin: 20px 80px;
  }
  .ant-checkbox-group-item {
    display: block;
    margin: 20px auto;
  }
  .ant-select-selection--single,
  .ant-select-selection--multiple {
    border-radius: 19px;
    width: 7rem;
    margin: 0 auto;
  }
}

// Job search page
.job-search-container {
  background-color: #ffffff;
  .main-title .head-title
  {
    
      letter-spacing: 0px;
      color: #4B426B;
      font-weight: 600;
      font-size: 27px;
      font-family: 'Poppins', sans-serif;
      opacity: 1;
      margin-top:7px;
    
  }
  .main-title {
    letter-spacing: 0px;
    color: #a96091;
    font-weight: 600;
    font-size: 48px;
    opacity: 1;
    font-family: 'Poppins', sans-serif;
    // margin: 2rem 0;
    
    @media screen and (max-width: 520px) 
      {
        color:#4B426B;
        font-size:27pt !important;
        font-family: 'Poppins', sans-serif;
        font-weight:400;
        width:77pt;
        height:33pt;
      }
      .searchicon-left
      {
        display: none;
      @media screen and (max-width: 520px) 
      {
        color:#965C89;
        opacity: 0.8;
        display: inline-block;
         margin-top: -10px;
         width: 29px;
         //height:15pt;
         font-size: 26px;
         margin-top: 1px;     
      }
      }
  }
  .wob-image{
   // width:96%;
    height:105%;
    margin-left:-23px;
    margin-top:-35px;
  }
  .wob-title{
    font-size:33px;
   // padding-top:131px;
    text-transform:uppercase;
   // padding-left:277px;
    font-weight:bold;
    color:#4B426B;
    font-family:Bebas Neue;
    width:100%;
  }

}
.filter-section
{
  margin-top: 49px;
}
/*
.joblist-section
{

}*/
.job-filters {
  padding: 4px 0;
  .filter-heading{
    font-size: 21px;
    color: $new-primary-color;
    font-weight: 400;
   // text-transform: uppercase;
    font-family: 'Poppins', sans-serif;
    margin-bottom: 0rem;
    line-height:1;
  }
  .filter-heading-wob
  {
    font-size: 14pt;
    color: $new-primary-color;
    font-weight: 600;
   // text-transform: uppercase;
    font-family: 'Poppins', sans-serif;
  }
  .filter-title {
    font-size: 17px;
    color: $new-primary-color;
   // font-weight: 600;
   // text-transform: uppercase;
    font-family: 'Poppins', sans-serif;
  }
  .ant-checkbox-group-item {
    display: block;
    margin-right: 8px;
    //padding: 5px 0;
    color: $new-primary-color;
    .ant-checkbox-inner{
      border-color: $new-primary-color;
      border-radius: 4px;
    }
  }
  .ant-select-selection--single{
    border:2px solid $new-primary-color;
    color: $new-primary-color;
    .ant-select-arrow .ant-select-arrow-icon{
      color: $new-primary-color;
      margin: 0px;
    }
  }
  .filter-button{
    width: 145px;
    height: 35px;
    background: transparent linear-gradient(90deg, #965C89 0%, #694B94 100%) 0% 0% no-repeat padding-box;
    border-radius: 12px;
    border-color: transparent;
    color: #fff;
    cursor: pointer;
    &:focus { 
      outline: none !important;
      }
  }
}
.outer-wobjob-bg
{
  padding-bottom: 53px;
 // background-color: #fff;
 // filter: drop-shadow(0px 12px 15px #00000029);
 // border-radius: 50px;
}
.ant-select-dropdown-menu-item{
  color: $new-primary-color;
}
.job-list-wrapper {
  // border-bottom: 1px solid #cbcbcb;
  background: #ffffff 0% 0% no-repeat padding-box;
  border-radius: 20px;
  margin-top: 15px;
  box-shadow: 0px 0px 12px #00000029;
  opacity: 1;
  @media screen and (max-width: 580px) {
    height: 80px;
    width: 105%;
    overflow-y: scroll;
    overflow-x: hidden;
    filter: drop-shadow(0pt 12pt 15pt #00000029);
  }
  .job-list-wrapper-details
  {
   max-width: 82.18%;
  }
  .job-logo
  {
    margin-left: 1.1%;
  }
  // .job-list-row {
  //   height: 500px;
  // }

  .btn-left
  {
    margin-right:20px;
  }
}
.job-list-wrapper:last-child {
  border-bottom: none;
}

.job-list {
  // margin-top: 1rem;
  // background: #ffffff 0% 0% no-repeat padding-box;
  // box-shadow: 0px 0px 12px #00000029;
  // border-radius: 15px;
  // opacity: 1;
  // max-height: 90%;
  // width: 930px;
  // .job-tile{
  //   overflow-y: scroll;
  // }
  // overflow-y: auto;
  // .row:after {
  //   content: ""; /* This is necessary for the pseudo element to work. */
  //   display: block; /* This will put the pseudo element on its own line. */
  //   margin: 0 auto; /* This will center the border. */
  //   width: 90%; /* Change this to whatever width you want. */
  //   padding-top: 20px; /* This creates some space between the element and the border. */
  //   border-bottom: 1px solid grey; /* This creates the border. Replace black with whatever color you want. */
  // }
  .job-image {
    //margin: 20px 40px;
    cursor: pointer;
    width:93%;
    @media screen and (max-width: 580px) {
      height: 47px;
      width: 85px;
      float: left;
      margin-right: 6px;
      margin-left: 1px;
      margin-top: 12px;
      }
  }
  .job-data-mobile {
    .job-title {
      font-family: 'Poppins', sans-serif;
      color: $new-primary-color;
      opacity: 1;
      font-size: 25px;
      font-weight: 600;
      @media screen and (max-width: 580px) {
        font-size: 12pt;
        width: 120pt;
        text-overflow: ellipsis;
        white-space: nowrap;
        overflow: hidden;
        text-align:left;
        //height: 20pt;
        }
    
    }
    .job-location {
      font-family: 'Poppins', sans-serif;
      color: $new-primary-color;;
      opacity: 1;
      font-size: 20px;
      @media screen and (max-width: 580px) {
        font-size: 10px;
       // padding-left: 85px;
        white-space: nowrap;
	      width: 118pt;
	      text-overflow: ellipsis;
	      overflow: hidden;
        }
    }
    .job-description {
      color: $new-primary-color;
      font-size: 12px;
      font-family: 'Poppins', sans-serif;
      overflow: hidden;
      text-align: justify;
      width: 90%;
     // color:#4B426B;
      @media screen and (max-width: 580px) {
        font-size: 10px;
        width: 80%;
        padding-left: 86px;
        }
    }
    .recommended-text {
      font: normal normal bold 12px/16px Avenir Next;
      color: #38b71a;
    }
  }
  .job-data {
    height: 183px;
    overflow-y: scroll;
    overflow-x: hidden;
    .job-title {
      font-family: 'Poppins', sans-serif;
      color: $new-primary-color;
      opacity: 1;
      font-size: 20px;
      font-weight: 600;
    
    }
    .job-location {
      font-family: 'Poppins', sans-serif;
      color: $new-primary-color;;
      opacity: 1;
      font-size: 17px;
    }
    .job-description {
      color: $new-primary-color;
      font-size: 12px;
      font-family: 'Poppins', sans-serif;
      height: auto;
      text-align: justify;
      width: 90%;
      min-height: 55px;
     // color:#4B426B;
      // margin-bottom: 50px;
    }
    .recommended-text {
      font: normal normal bold 12px/16px Avenir Next;
      color: #38b71a;
    }
  }
  .job-posted {
    color: $new-primary-color;
    margin-right: 14px;
    margin-top: 20px;
    font-size: 12px;
    font-family: 'Poppins', sans-serif;
    @media screen and (max-width: 580px) {
      margin-top: 3px;
    }
  }
  .apply-button {
    margin :0px 10px 10px 0px ;
   
    width: 175px;
    height: 45px;
    background: transparent linear-gradient(90deg, #965C89 0%, #694B94 100%) 0% 0% no-repeat padding-box;
    border-radius: 15px;
    border-color: transparent;
    color: #fff;
    cursor: pointer;
    &:focus { 
      outline: none !important;
      }
    @media screen and (max-width: 580px) {
      margin :0px -42px 10px 0px;
      width: 73px;
      height: 32px;
      font-size: 10px;
      }
  }
  .btn-more
  {
    @media screen and (max-width: 580px) {
    margin-top: -44px;
    margin-right: -12px;
    }
  }
}
.middle-column-widgets {
  // box-sizing: content-box;
  display: inline-block;
}

.joblist-pagination {
  .ant-pagination-prev .ant-pagination-item-link,.ant-pagination-next .ant-pagination-item-link {
    border: none;
    padding-bottom: 3px;
    .anticon-left,.anticon-right{
      font-size: 20px;
      color:$new-primary-color;
    }
  }
  .ant-pagination-item{
    border-color: $new-primary-color;
    border-width: 2px;
    color: $new-primary-color;
  }
  .ant-pagination-item-active{
    background-color:$new-primary-color;
    color: white;
    border-color: $new-primary-color;
  }
}

//Webinar Live Page
.webinar-live-container {
  .webinar-live-title {
    color: #a96091;
    font-size: 35px;
    margin-left: 67px;
    margin-top: 46px; // font-family: Avenir Next;
  }
  .webinar-live-title:after {
    content: "";
    border-bottom: 4px solid #3e9da4;
    width: 5%;
    display: block;
    margin-top: 7px;
    // margin: 0 auto;
  }
  .webinar-topic {
    font: normal normal normal 46px/63px Avenir Next;
    color: #66449b;
    margin-left: 67px;
    margin-top: 10px;
  }
  .presenter {
    color: #66449b;
    font: normal normal normal 31px/32px Avenir Next;
    margin-left: 67px;
  }
  .designation {
    font: normal normal normal 25px/34px Avenir Next;
    letter-spacing: 0px;
    color: #6c6c6c;
    display: inline-block;
    margin-left: 40px;
  }
  .webinar-live-video {
    background: #626262 0% 0% no-repeat padding-box;
    box-shadow: 0px 0px 30px #00000015;
    border-radius: 15px;
    width: 100%;
    height: 532px;
  }
}

.swiper-button-next {
  color: #b77bac !important;
}
.swiper-button-next:after {
  margin-left: 72px !important;
  color: #694B94 !important;
  @media screen and (max-width: 580px)
  {
  margin-left: 109px !important;
  font-weight: bold;
  font-size: 28px !important;
  margin-top:40px;
  }
}

.swiper-button-prev {
  color: #694B94 !important;
  font-size:10px;
}
.swiper-button-prev:after {
  margin-right: 72px !important;
  @media screen and (max-width: 580px)
  {
  margin-left: -16px !important;
  font-weight: bold;
  font-size: 28px !important;
  margin-top:40px;
  }
 
}

// JOB PROFILE PAGE

.job-profile-heading-container {
  display: flex;
  margin-bottom: 79px;
  justify-content: space-evenly;
  .job-profile-heading-text {
    color: #a96091;
    font-size: 65px;
    // margin-left: 67px;
    margin-top: 79px;
  }
  .job-profile-heading-text:after {
    content: "";
    border-bottom: 5px solid #3e9da4;
    width: 25%;
    display: block;
    margin-top: 10px;
  }

  .job-search {
    margin-top: 79px;
    margin-right: 100px;
    // margin-left: 100px;
  }
}
.job-container-mobile {
  padding-bottom: 50px;
  .job-basic {
    // width: 208px;
    padding-left: 15px;
    padding-right: 5px;
    padding-top: 15px;
    padding-bottom: 15px;
    margin: 0 auto;
    // border: 1px solid red;
    .job-image {
      // height: 150px;
      // background: #ffffff;
      margin-top: 23px;
      // img {
      //   // height: 100px;
      //   width: 100%;
      // }
    }
    .company-name {
      margin-top: 15px;
     // font: normal normal bold 30px/41px Avenir Next;
      color: #4B426B;
      white-space: nowrap;
      font-family:poppins,sans-serif;
      font-size:16pt;
      font-weight:800;
    }
    .job-location {
      margin-top: 10px;
      font: normal normal normal 18px/25px Avenir Next;
      color: #707070;
    }
    .company-details {
     // font: normal normal bold 20px/27px Avenir Next;
     font-family:poppins,sans-serif;
      color: #4B426B;
      font-size:12pt;
      font-weight:600;
      text-transform: uppercase;
    }
    .job-description {
     // font: normal normal normal 16px/18px Arial;
     // color: #707070;
    
     color:#4B426B;
     font-size:12pt;
     font-family:poppins,sans-serif;
      //width: 361px;
      max-height: 200px;
      overflow-y: scroll;
      overflow-x: hidden;
      margin-bottom: 26px;
    }
    .job-description::-webkit-scrollbar {
      display: none;
    }
  }

  .job-main {
    .job-card {
     // padding: 40px 30px;
      // width: 680px;
      //height: 400px;
      background: #ffffff 0% 0% no-repeat padding-box;
      box-shadow: 0px 0px 12px #00000029;
      border-radius: 0 0 40pt 40pt;
      overflow-y: auto;
      overflow-x: hidden;

      .job-heading {
        display: flex;
        justify-content: space-between;
        padding-left: 24%;
        padding-top: 27px;
        .job-title {
          // width: 220px;
          // height: 67px;
          word-wrap: break-word;
          overflow: hidden;
          color: #4B426B;
          font-size: 29px;
          font-weight: bold;
          line-height: 1.2;
          font-family:poppins,sans-serif;
        }

        .heading {
          // margin-top: 4px;
          margin-right: 30px;
        }
        .apply-button {
          background: transparent linear-gradient(250deg, #68439a 0%, #aa5b8c 100%) 0% 0% no-repeat padding-box;
          color: #fff !important;
          border-radius:8pt;
          width: 79pt;
          height: 24pt;
          font-size: 14pt;
          text-align: center;
          font-weight: 600;
          
          // margin-left: 14px;
        }
      }

      .job-details {
        .keypoints {
          margin-left: 30px;
        }
        // margin-top: 59px;
        .text {
         // display: inline-block;
          margin-left: 27px;
          font-size: 12pt;
          color:#4B426B;
          font-weight:600;
          margin-bottom: 5px;
          margin-top: -3px;
          font-family:poppins,sans-serif;
        }
        .job-filter-value {
          font-size: 10pt;
          margin-left: 10px;
          margin-top: 2px;
          font-weight: normal;
          color: #4B426B;
          font-family:poppins,sans-serif;
        }
        .text-output {
          overflow-x: hidden;
        }
        .c-1,
        .c-2 {
          display: flex;
          flex-direction: column;
          align-items: flex-start;
          justify-content: space-between;
          // padding-left: 33px;
        }
        .circle,
        .common {
          margin-top: 20px;
        }
      }
      .title{
        font-weight:600;
        color: #4B426B;
        font-family:poppins,sans-serif;
        font-size: 14pt;
        text-transform: uppercase;
      }
    }

    .jd-video {
      margin-top: 40px;
      // border: 1px solid #707070;
      border-radius: 10px;
      box-shadow: 0px 0px 12px #00000029;
      // width: 678px;
      height: 345px;
      overflow: hidden;
    }

    .jd-text {
      // width: 680px;
      min-height:175px;
      background: #ffffff 0% 0% no-repeat padding-box;
      box-shadow: 0px 0px 12px #00000029;
      border-radius: 10px;
      margin-top: 40px;
      padding: 30px;
      overflow-y: auto;

      .jd-title {
       // font: normal normal bold 20px/27px Avenir Next;
        color: #4B426B;
        font-family:poppins,sans-serif;
        font-size:14pt;
        font-weight:600;
        text-transform: uppercase;
        
      }
      .jd-description {
        color: #707070;
        font-size: 16px;
        line-height: 18px;
      }
    }
  }
  // column -3
  .related-jobs-title {
    padding-left: 15px;
    font-size: 14pt;
    color: #4B426B;
    font-family:poppins,sans-serif;
    font-weight:600;
    margin-top:20px;
  }
  .job-list-item {
    .job-tile-top {
      display: flex;
      flex-direction: row;
      .job-tile-image-div {
        width: 108px;
        height: 65px;
        .job-tile-image {
          max-width: 100%;
          max-height: 100%;
          margin: auto;
          display: block;
        }
      }
      .job-tile-hiring {
        margin-left: 30px;
        display: flex;
        .job-active-icon {
          margin-top: 9px;
          margin-right: 5px;
        }
        .job-tile-hiring-text {
          font-size: 9pt;
          color: #4B426B;
          font-family:poppins,sans-serif;
        }
      }
    }
    .job-title {
      font-size: 16pt;
      color: #4B426B;
      max-width: 171px;
     // white-space: nowrap;
      // max-height: 30px;
      overflow: hidden;
      text-overflow: ellipsis;
      word-wrap: break-word;
      margin-bottom: 0 !important;
    }
  }
  .circle {
    background: #ffffff 0% 0% no-repeat padding-box;
    border-radius: 50px/50px;
    border: 1px solid #707070;
    opacity: 1;
    width: 30px;
    height: 30px;
    display: inline-block;
  }
  .big-circle {
    background: #ffffff 0% 0% no-repeat padding-box;
    border-radius: 50px/50px;
    border: 1px solid #707070;
    opacity: 1;
    width: 45px;
    height: 45px;
    display: inline-block;
  }
  .bookmark {
    background: transparent url("../assets//bookmark.svg") 0% 0% no-repeat padding-box;
    width: 30px;
    height: 30px;
    margin-top: 11px;
    margin-left: 12px;
  }
  .common {
    // width: 30px;
    height: 30px;
    display: inline-block;
  }

  .star {
    background: transparent url("../assets//star.svg") 0% 0% no-repeat padding-box;
  }
  .book {
    background: transparent url("../assets//book-open.svg") 0% 0% no-repeat padding-box;
  }
  .flag {
    background: transparent url("../assets//flag.svg") 0% 0% no-repeat padding-box;
  }
  .home {
    background: transparent url("../assets//home.svg") 0% 0% no-repeat padding-box;
  }
  .video {
    background: transparent url("../assets//video.svg") 0% 0% no-repeat padding-box;
  }
  .user {
    background: transparent url("../assets//user.svg") 0% 0% no-repeat padding-box;
  }
  .map-pin {
    background: transparent url("../assets//map-pin.svg") 0% 0% no-repeat padding-box;
  }
}
.job-container {
  padding-bottom: 50px;
  .job-basic {
    // width: 208px;
    padding: 15px;
    margin: 0 auto;
    // border: 1px solid red;
    .job-image {
      // height: 150px;
      // background: #ffffff;
      img {
        // height: 100px;
        width: 100%;
      }
    }
    .company-name {
      margin-top: 15px;
     // font: normal normal bold 30px/41px Avenir Next;
      color: #4B426B;
      white-space: nowrap;
      font-family:poppins,sans-serif;
      font-size:16pt;
      font-weight:600;
    }
    .job-location {
      margin-top: 10px;
      font: normal normal normal 18px/25px Avenir Next;
      color: #707070;
    }
    .company-details {
     // font: normal normal bold 20px/27px Avenir Next;
     font-family:poppins,sans-serif;
      color: #4B426B;
      font-size:12pt;
      font-weight:600;
      text-transform: uppercase;
    }
    .job-description {
    //  font: normal normal normal 16px/18px Arial;
     // color: #707070;
      //width: 208px;
      // height: 390px;
      overflow-y: auto;
      overflow-x: hidden;
      color:#4B426B;
      font-family:poppins,sans-serif;
      font-size:10pt;
      .ant-typography-expand
      {
        color: #cb3438;
      }
    }
    .job-description::-webkit-scrollbar {
      display: none;
    }
  }

  .job-main {
    .job-card {
      padding: 20px 30px;
      // width: 680px;
      height: auto;
      background: #ffffff 0% 0% no-repeat padding-box;
      box-shadow: 0px 0px 12px #00000029;
      border-radius: 10px;
      // overflow-y: auto;
      // overflow-x: hidden;

      .job-heading {
        display: flex;
        justify-content: space-between;
        .job-title {
          // width: 220px;
          // height: 67px;
          word-wrap: break-word;
          overflow: hidden;
          color: #4B426B;
          font-size: 14pt;
          font-weight:600;
          line-height: 1.2;
          font-family:poppins,sans-serif;
          text-transform: uppercase;
        }

        .heading {
          // margin-top: 4px;
          margin-right: 30px;
        }
        .apply-button {
          background: transparent linear-gradient(250deg, #68439a 0%, #aa5b8c 100%) 0% 0% no-repeat padding-box;
          color: #fff !important;
          border-radius: 25px;
          width: 125px;
          height: 35px;
          font-size: 14px;
          text-align: center;
          font-weight: 600;
          // margin-left: 14px;
        }
      }

      .job-details {
        .keypoints {
          margin-left: 30px;
        }
        // margin-top: 59px;
        .text {
          display: inline-block;
          margin-left: 35px;     
          margin-bottom: 5px;
          font-family:poppins,sans-serif;
          text-transform: uppercase;
          font-size: 14px;
          color: #4B426B;
          font-weight: 600;
          font-family: 'Poppins', sans-serif;
        }
        .job-filter-value {
          font-size: 14px;
          margin-left: 35px;
          margin-top: 0;
          font-weight: normal;
          font-family:poppins,sans-serif;
          color:#4B426B;
        }
        .text-output {
          overflow-x: hidden;
        }
        .c-1,
        .c-2 {
          display: flex;
          flex-direction: column;
          align-items: flex-start;
          justify-content: space-between;
          // padding-left: 33px;
        }
        .circle,
        .common {
          margin-top: 10px;
          margin-bottom: 40px;
        }
      }
    }

    .jd-video {
      margin-top: 40px;
      // border: 1px solid #707070;
      border-radius: 10px;
      box-shadow: 0px 0px 12px #00000029;
      // width: 678px;
      height: 345px;
      overflow: hidden;
    }

    .jd-text {
      // width: 680px;
      height: 400px;
      background: #ffffff 0% 0% no-repeat padding-box;
      box-shadow: 0px 0px 12px #00000029;
      border-radius: 10px;
      margin-top: 40px;
      padding: 30px;
      overflow-y: auto;

      .jd-title {
        color: #4B426B;
        font-family:poppins,sans-serif;
        font-size:14pt;
        font-weight:600;
        text-transform: uppercase;
      }
      .jd-description {
        color: #707070;
        font-size: 16px;
        line-height: 18px;
      }
    }
  }
  // column -3
  .related-jobs-title {
    padding-left: 15px;
    font-size: 25px;
    color: #4B426B;
    font-family:poppins,sans-serif;
    font-weight:600;
  }
  .job-list-item {
    .job-tile-top {
      display: flex;
      flex-direction: row;
      .job-tile-image-div {
        width: 108px;
        height: 65px;
        .job-tile-image {
          max-width: 100%;
          max-height: 100%;
          margin: auto;
          display: block;
        }
      }
      .job-tile-hiring {
        margin-left: 30px;
        display: flex;
        .job-active-icon {
          margin-top: 9px;
          margin-right: 5px;
        }
        .job-tile-hiring-text {
          font-size: 9pt;
          color: #4B426B;
          font-family:poppins,sans-serif;
        }
      }
    }
    .job-title {
      font-size: 14pt;
      color: #4B426B;
      max-width: 171px;
      white-space: nowrap;
      // max-height: 30px;
      overflow: hidden;
      text-overflow: ellipsis;
      word-wrap: break-word;
      margin-bottom: 0 !important;
      font-family:poppins,sans-serif;
    }
  }
  .circle {
    background: #ffffff 0% 0% no-repeat padding-box;
    border-radius: 50px/50px;
    border: 1px solid #707070;
    opacity: 1;
    width: 30px;
    height: 30px;
    display: inline-block;
  }
  .big-circle {
    background: #ffffff 0% 0% no-repeat padding-box;
    border-radius: 50px/50px;
    border: 1px solid #707070;
    opacity: 1;
    width: 45px;
    height: 45px;
    display: inline-block;
  }
  .bookmark {
    background: transparent url("../assets//bookmark.svg") 0% 0% no-repeat padding-box;
    width: 30px;
    height: 30px;
    margin-top: 11px;
    margin-left: 12px;
  }
  .common {
    // width: 30px;
    height: 30px;
    display: inline-block;
  }

  .star {
    background: transparent url("../assets//star.svg") 0% 0% no-repeat padding-box;
  }
  .book {
    background: transparent url("../assets//book-open.svg") 0% 0% no-repeat padding-box;
  }
  .flag {
    background: transparent url("../assets//flag.svg") 0% 0% no-repeat padding-box;
  }
  .home {
    background: transparent url("../assets//home.svg") 0% 0% no-repeat padding-box;
  }
  .video {
    background: transparent url("../assets//video.svg") 0% 0% no-repeat padding-box;
  }
  .user {
    background: transparent url("../assets//user.svg") 0% 0% no-repeat padding-box;
  }
  .map-pin {
    background: transparent url("../assets//map-pin.svg") 0% 0% no-repeat padding-box;
  }
  .ctc {
    background: transparent url("../assets//ctc.svg") 0% 0% no-repeat padding-box;
  }
}

// Webinar Modal

.modal-header {
  display: flex;
  border-bottom: none !important;
  .modal-webinar-information {
    width: 500px;
    margin-top: 20px;
    margin-bottom: 20px;
  }
  .modal-basic-details {
    margin-right: 40px;
    .modal-speaker-title {
      color: #734099;
      font-size: 44px;
      font-weight: 700;
    }
    .modal-webinar-title {
      font-size: 28px;
      color: #734099;
      font-weight: 600;
    }
    .modal-image {
      width: 368px;
      height: 369px;
      .image-presenter {
        max-width: 100%;
        max-height: 100%;
        margin-top: 30px;
      }
    }
  }
  .modal-webinar-details {
    margin-left: 50px;
    color: #707070;
    .modal-presenter-title {
      font-size: 28px;
    }
    .modal-webinar-description {
      margin-top: 40px;
    }
  }
  .event-div {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-right: 150px;
    .details-logo {
      padding-right: 10px;
    }
    h6 {
      font-size: 20px;
      color: #222222;
    }
  }
}

.modal-body {
  display: flex;
  .modal-webinar-information {
    width: 500px;
    margin-top: 20px;
  }
  .title-info {
    color: #5d5b5f;
    font-size: 24px;
  }
  .modal-webinar-title {
    font-size: 28px;
    color: #734099;
    font-weight: 800;
  }
  .event-div {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-right: 150px;
    .details-logo {
      padding-right: 10px;
    }
    h6 {
      font-size: 20px;
      color: #222222;
    }
  }

  .event-details {
    color: #707070;
    font-size: 18px;
    font-weight: 600;
  }

  .modal-registration-form {
    background: #f8f6fa;
    width: 560px;
    padding-bottom: 38px;
    .form-title {
      color: #5d5b5f;
      font-size: 28px;
      font-weight: 600;
      padding-bottom: 15px;
      font-style: italic;
      padding-left: 10px;
      padding-top: 10px;
    }
    .registration-form {
      margin-left: 20px;
    }
    .ant-input,
    .ant-input-number {
      border: 1px solid #a0a0a0;
      border-radius: 10px;
      width: 520px;
    }

    .registration-button {
      background: no-repeat padding-box #734099;
      border: 1px solid #707070;
      border-radius: 15px;
      padding: 20px;
      font-size: 18px;
      width: 200px;
      height: 60px;
      text-align: center;
      line-height: 1;
      color: #fff;
      margin: auto !important;
      display: block;
    }
  }

  .ant-form-item {
    margin-bottom: 0px !important;
  }
}

.webinar-modal-video-title {
  margin: 20px auto;
  .webi-video-title
  {
    font-size:14pt;
    color:#4B426B;
    font-family:poppins,sans-serif;
    font-weight:600;
  }
}

////RESPONSIVE STYLES (Webinar Page)

@media screen and (max-width: 411px) {
  .job-search-button {
    width: 361px;
  }
  .job-search-button .ant-btn-primary {
    width: 93px;
    margin-right: 27px;
  }
  .webinar-title {
    font-size: 30px;
  }
  .webinar-search {
    margin-left: 30px;
  }
  .webinar-tiles {
    margin-left: 27px;
  }
  .webinar-dropdown {
    display: none !important;
  }
}
//Landing Page Responsive
@media screen and (max-width: 580px) {
  .hero-text {
    margin-top: 30px;
    text-align: center;
  }
  .ant-input-affix-wrapper .ant-input:not(:last-child) {
    /*padding-right: 60px;
    border-radius: 34px;
    height: 48px;*/
    padding-right: 106px;
    border-radius: 12px;
    height: 45px;
    top: 1px;
  }
  .ant-input-affix-wrapper .ant-input-suffix {
    right: 12px;
  }
  .campaign-modal,
  .dbs-campaign-modal {
    width: 100% !important;
    .dbs-image {
      height: 350px;
      border-radius: 3%;
    }
    h2 {
      font-size: 18px;
    }
  }
 
}

.akiwre-hero-heading-text {
  font-size: 48px;
  margin-top: 20px;
  font-weight: 900;
  color: #122081 !important;
}

.akiwre-section-heading-text {
  font-size: 40px;
  margin-top: 20px;
  font-weight: 900;
  color: #122081 !important;
}

// Akwire How it works
.akwire-howitworks-container {
  background: #fff;
  padding: 60px 0;
  h2 {
    color: #122081;
    font-size: 36px;
    font-weight: 700;
  }
  .video-wrapper {
    display: flex;
    justify-content: center;
    height: 600px;
    @media screen and (max-width: 768px) {
      height: auto;
    }
    // background: #303030 0% 0% no-repeat padding-box;
    // border: 1px solid #707070;
  }
  .plyr video {
    display: block;
    width: 100%;
    height: auto;
  }
}

.akwire-products-container {
  background: transparent linear-gradient(252deg, #0617a4 0%, #19256c 100%) 0% 0% no-repeat padding-box;
  font-family: "Nunito", sans-serif;
  padding: 60px 0;
}
@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@600&display=swap');
.company--profile-wrapper {
  // position: relative;
  padding-top: 100px;
  background-color: $white-color;
  .ant-collapse {
    border: none;
    background-color: transparent;
    border-radius: 20px;
  }
  .ant-collapse > .ant-collapse-item:last-child,
  .ant-collapse > .ant-collapse-item:last-child > .ant-collapse-header {
    border-radius: 0;
    // border-radius: 0 0 6px 6px;
  }
  .ant-collapse > .ant-collapse-item > .ant-collapse-header {
    color: $white-color;
    background-color: #002a5e;
  }
  .ant-collapse > .ant-collapse-item {
    border-bottom: none;
    margin-bottom: 30px;
    @media screen and (max-width: 520px) {
      margin-bottom: 0px;
     }
  }
  .ant-collapse-content > .ant-collapse-content-box {
    background-color: #002a5e;
  }
  .ant-collapse-content {
    border-top: none;
  }
  .company-about-item {
    margin: 20px 0;
    .company-about-item-icon{
      font-size: 15px;
      color: $new-primary-color;
    }
    .company-about-item-website {
      text-transform: uppercase;
      font-size: 14px;
      color: $new-primary-color;
      font-weight:600 ;
      font-family: 'Poppins', sans-serif;
      
    }
    .company-about-item-stats {
      color: $new-primary-color;
      font-weight: 500;
      text-align: left;
      font-family: 'Poppins', sans-serif;
      font-size: 13px;

    }
  }
  .company-item-mobile
  {
    @media screen and (max-width: 520px) {
     display:flex;
    }
  }
}
.company--profile__banner-bg {
  height: 275px;
  background-color: #c1dbfc !important;
}
.company--profile__banner-wrapper {
  
  margin-bottom: 10px;
}
.company--profile__logo-wrapper {
  width: 175px;
  // height: 175px;
  border-radius: 20px;
  margin-left: 30px ;
}
.company--profile__logo {
  // height: 65px;
  width: 90%;
  

}
.company--profile__about-card {
  height: 200px;
  overflow-y: scroll;
  padding: 20px;
  margin-bottom: 20px;

  background: #ffffff 0% 0% no-repeat padding-box;
  box-shadow: 0px 5px 8px 1px #00000029;
  border-radius: 30px;

  .company--profile__about-card--overview {
   
    text-align: left;
    font: normal normal bold 24px/27px Arial;
    letter-spacing: 0px;
    color: $new-primary-color;
    font-family: 'Poppins', sans-serif;
  }
  .company--profile__about-card--text {
    text-align: left;
    // height: 200px;
    font-size: 13px;
    padding: 5px 10px 5px 5px;
     font-family: 'Poppins', sans-serif;
    line-height: 1.5;
    letter-spacing: 0px;
    color: $new-primary-color;
    @include custom-scrollbar();
    &::-webkit-scrollbar {
      width: 5px !important;
    }
    &::-webkit-scrollbar-thumb {
      height: 40px;
    }
  }
}
.company--profile__leader-speak-card {
  background: #ffffff 0% 0% no-repeat padding-box;
  box-shadow: 0px 0px 10px #00000029;
  border-radius: 14px;
  height: 290px;
  margin: 20px 0;
  padding: 10px;
  .leader-speak-text {
    margin-top: 15px;
    text-align: left;
    font: italic normal bold 10px/11px Arial;
    letter-spacing: 0px;
    color: #000000;
    margin-bottom: 10px;
  }
  .leader-speak-name {
    font-weight: bold;
    font-size: 16px;
    text-align: left;
    letter-spacing: 0px;
    color: #000000;
    margin-bottom: 10px;
  }
  .leader-speak-designation {
    text-align: left;
    font: normal normal normal 12px/12px Arial;
    letter-spacing: 0px;
    color: #000000;
    margin-bottom: 10px;
  }
  .leader-speak-description {
    height: 160px;
    font-size: 15px;
    padding: 5px 10px 5px 5px;
    font-family: $black-color;
    @include custom-scrollbar();
    &::-webkit-scrollbar {
      width: 5px !important;
    }
    &::-webkit-scrollbar-thumb {
      height: 40px;
    }
  }
  .leader-speak-image-wrapper {
    height: 80px;
    width: 80px;
    border-radius: 50px;
    border: 1px solid #707070;
    background: #ffffff 0% 0% no-repeat padding-box;
    display: flex;
    align-items: center;
  }
  .leader-speak-image {
    width: 100%;
    margin: auto 0;
  }
}
.company-social-links {
  li {
    display: inline;
    margin-left: 20px;
  }
  list-style: none;
  .footer-social-icons {
    font-size: 22px;
    // display: flex;
    // align-items: center;
    justify-content: center;
  }
}
.job-item-wrapper {
  padding: 10px;
  height: 700px;
  border-radius: 30px;
  background-color: $white-color;
  overflow-y: scroll;
  // border: 1px #00000029 solid;
  box-shadow: 0 5px 5px 1px #00000029;
.job-item-wrapper-title{
    margin: 10px 0px 20px 10px;
    font-size: 25px;
    font-family: 'Poppins', sans-serif;
    font-weight: 600;
    color: $new-primary-color;
}
  .job-item-name,
  .job-item-location {
    @import url('https://fonts.googleapis.com/css2?family=Poppins:wght@500;600&display=swap');
    text-align: left;
    font-family: 'Poppins', sans-serif;
    letter-spacing: 0px;
    color: $new-primary-color;
    font-size: 14px;
  }
  .job-item-apply-btn {
    background:linear-gradient(90deg, #965C89 0%, #694B94 100%);
    color: #FFFFFF;
    border-radius: 11px;
    width: 110px;
    height: 35px;
    margin-bottom: 13px;
    font-family: 'Poppins', sans-serif;
    font-weight: 600;
    font-size: 14px;
  }
}

// RASA CHAT WIDGET
.rw-conversation-container {
  a {
    color: rgba(1, 1, 1, 0.8);
  }
  .rw-header,
  .rw-client {
    background: #68439a !important;
    font-family: "Lato", sans-serif;
  }
  .rw-response {
   // background-color: $secondary-color;
   // color: $white-color;
    font-family: "Lato", sans-serif;
  }
  .rw-reply
  {
    background: #68439a !important;
    color:white;
    border:1px solid #68439a !important;
  }
  .rw-messages-container {
    @include custom-scrollbar();
  }
  .rw-header
  {
    background:$primary-color !important;
  }
  .rw-send .rw-send-icon-ready
  {
    fill:#68439a !important;
  }
}
.rw-widget-container .rw-launcher {
  background-color: $primary-color;
}

// .rw-conversation-container
//   |-- .rw-header
//         |-- .rw-title
//         |-- .rw-close-function
//         |-- .rw-loading
//   |-- .rw-messages-container
//         |-- .rw-message
//               |-- .rw-client
//               |-- .rw-response
//         |-- .rw-replies
//               |-- .rw-reply
//               |-- .rw-response
//         |-- .rw-snippet
//               |-- .rw-snippet-title
//               |-- .rw-snippet-details
//               |-- .rw-link
//         |-- .rw-imageFrame
//         |-- .rw-videoFrame
//   |-- .rw-sender
//         |-- .rw-new-message
//         |-- .rw-send


.banner__heading{
  font-size:25px;
}

/********************************** solutions section ************************************/

.solution-tab{
.ant-card-body{
  padding: 16px;
}
  .solution-tab-card,.feature-blog-tab-card,.feature-tab-card {
     width: 100%;
     box-shadow:0px 7px 15px #00000014;
     border-radius:20px;
    //  margin-top: 10px;
     @media screen and (max-width: 580px)
     {
       border-top: 0;
       border-left: 0;
       border-right: 0;
       width: 107%;
       margin-left: -16px;
     }
     .solution-tab-card-img{
      padding-top:10px;
      margin: auto;
     }
      
    .solution-tab-card-video,.solution-tab-card-image2{
      margin-bottom:5px;
      height:160px;
     }
     .solution-tab-card-text{
      text-align:center;
      color:$new-primary-color;
      font-size:10px;
      font-family: 'Poppins', sans-serif; 
     // margin-left:-39px;
      @media only screen and (max-width: 580px) {
        font-size: 12px;
        white-space: initial;
        
      }
    }
    .position-text {
        font-family:poppins,sans-serif;
        color:#4B426B;
        font-size:12px;
    }
     .solution-tab-card-button{
      // margin-top:13px;
      text-align:center;
      .btn-section{
        width:130px;
          border-radius:8px;
          cursor: pointer;
          background:linear-gradient(90deg, #965C89 0%, #694B94 100%);
          border:none;
          height:35px;
          @media screen and (max-width: 580px)
          {
          width:142px;
          height:40px;
          border-radius:12px;
          a{
            font-size:18px;
          }
          }
          
      }
    }
  
   
    }
    .solution-tab-image2{
      height: 175px;
      margin-bottom: 20px;
    }
    .feature-blog-tab-card, .feature-tab-card{
      min-height: 440px;
     
      
        .ant-card-head{
        color: $new-primary-color;
        text-align: center;
        font-family: 'Poppins', sans-serif; 
        font-size: 22px;
        font-weight: 600;
        // margin-top: 10px;
        // height: 10px;
        border-bottom: none;
      }
       .options-wrapper{
      
        .ant-progress-show-info .ant-progress-inner {
          border-radius: 0 !important ;
          height: 15px;
        }
        .ant-progress-bg {
      
          // position: relative;
          background-color: #965C89 ;
          height: 18px !important;
          border-radius: 0;
        }
        .ant-progress-text{
          color: $new-primary-color;
        }
        .poll-options{
          color: $new-primary-color;
          font-family: 'Poppins', sans-serif; 
          font-size: 12px;
          margin: 0px;
          line-height: 18px;
        }
        .feature-tab-image{
          width: 80%;
          height: 210px;
        
        }
        .count-wrapper,.webinar-text-title{
          color: $new-primary-color;
        }

        .blog-text,.blog-Title{
          color: #4B426B;
          
          font-family: 'Poppins', sans-serif;
        }
        .blog-text h6{
          font-size: 13px;
          margin-top: 2px;
        }
        .blog-text p{
          font-size: 11px;
          font-weight: 400;
        }
        .text-left
        {
          font-size:10px;
        }
        .blog-Title{
          font-size: 10px;
        }
        .solution-tab .feature-blog-tab-card .blog-text-description{
          font-size:9px;
        }
       
      }
      .ant-card-body{
        padding: 16px;
      }
      .ant-card-actions{
        background: #FFFFFF;
        border-top: none;
        border-radius: 20px;
      }
      .ant-card-actions > li {
        float: left;
         margin-top: 0px; 
        background-color: #FFFFFF;
        text-align: center;
    }
    .ant-card-actions > li > span a:not(.ant-btn){
      color: white;
    }
      .webinar-text-presenter{
        color: $new-primary-color;
        font-size:16px;
      }
      .blog-text-description,.company-info-wrapper{
        color: #4B426B;
        font-size: 12px;
        font-family: 'Poppins', sans-serif;
        white-space: nowrap;
        overflow: hidden;
        text-overflow:ellipsis;
      }
      .company-info-wrapper{
        font-size: 14px;
      }
       .option_img{
         height: 140px;
         overflow:hidden;
         img{
           height: 100%;
         }
       }
  }
  
  }
  



@media (min-width: 992px) and (max-width: 1150px) {
  .banner__heading{
    font-size:20px;
  }
}
.modal-header_V1 {
  display: flex;
  margin-bottom: 12px;
  .content
  {
    padding-right: 15px;
    width:50%;
    float: left;
    .paragraphcontent
    {
      height: 300px;
      overflow: auto;
      margin-top: 20px;
      margin-bottom: 20px;
      //border:1px solid black;
    }
    .image-presenter
    {
      width: 60%;
    }
    .modal-presenter-title {
      font-size: 28px;
    }
    .event-details {
      color: #707070;
      font-size: 28px;
      font-weight: 600;
    }
  }
 
  .modal-webinar-title {
    font-size: 28px;
    color: #734099;
    font-weight: 600;
  }
  .event-div {
    display: flex;
    justify-content: space-between;
    align-items: center;
    //padding-right: 150px;
    .details-logo {
      padding-right: 10px;
      margin-top: -5px;
    }
    h6 {
      font-size: 20px;
      color: #222222;
    }
  }
  .modal-registration-form {
    background: #f8f6fa;
    width: 560px;
    padding-bottom: 38px;
    .form-title {
      color: #5d5b5f;
      font-size: 28px;
      font-weight: 600;
      padding-bottom: 15px;
      font-style: italic;
      padding-left: 10px;
      padding-top: 10px;
    }
    .registration-form {
      margin-left: 20px;
    }
    .ant-input,
    .ant-input-number {
      border: 1px solid #a0a0a0;
      border-radius: 10px;
      width: 520px;
    }

    .registration-button {
      background: no-repeat padding-box #734099;
      border: 1px solid #707070;
      border-radius: 15px;
      padding: 20px;
      font-size: 18px;
      width: 200px;
      height: 60px;
      text-align: center;
      line-height: 1;
      color: #fff;
      margin: auto !important;
      display: block;
    }
  }
  .ant-form-item {
    margin-bottom: 0px !important;
  }
}

.modal-registration-form_V1 {
  background: #f8f6fa;
  width: 560px;
  padding-bottom: 38px;
  .form-title {
    color: #5d5b5f;
    font-size: 28px;
    font-weight: 600;
    padding-bottom: 15px;
    font-style: italic;
    padding-left: 10px;
    padding-top: 10px;
  }
  .registration-form {
    margin-left: 20px;
  }
  .ant-input,
  .ant-input-number {
    border: 1px solid #a0a0a0;
    border-radius: 10px;
    width: 520px;
  }

  .registration-button {
    background: no-repeat padding-box #734099;
    border: 1px solid #707070;
    border-radius: 15px;
    padding: 20px;
    font-size: 18px;
    width: 200px;
    height: 60px;
    text-align: center;
    line-height: 1;
    color: #fff;
    margin: auto !important;
    display: block;
  }
}
@media only screen 
and (min-device-width : 320px) 
and (max-device-width : 568px) 
and (orientation : portrait) { /* STYLES GO HERE */
  .ant-modal-content
  {
    min-width: 336px;
  }
}
@media(max-width: 320px){ 
  .ant-modal-content
  {
    min-width: 336px;
  }
}
@media only screen and (max-width: 620px) {
  /* For mobile phones: */
  .modal-header_V1 {
    display: grid;
  .content {
    width: 100%;
    .image-presenter
    {
      width: 100%;
    }
   
  }
  
  .modal-webinar-title {
   text-align: initial;
   font-size: 31px;
  }
  .event-div {
    display: contents;
    margin-bottom: 12px;
  }
}

.modal-registration-form_V1 {
  width: auto;
  .registration-form {
    margin-left: 20px;
  }
  .ant-input,
  .ant-input-number {
    width: 290px;
  }

  .registration-button {
    width: auto;
  }
  .ant-form-item-control
  {
    width: 108%;
  }
}
}
@media only screen and (max-width: 580px) 
{
.hero-section
{
  height:auto;
}
.hero .hero-image
{
  height: auto;
  margin-right: auto;
  @media only screen and (max-width: 580px) 
  {
    float:right;
  }
}
}
@media only screen and (min-width: 1024px){
  
  /* CSS */
  .hero
  {
    height:auto;
  }
}
.Mobileview
{
  padding-left: 0px;
  .solution-tab
  {
    padding-left: 0px;
    // padding-right: 0px;
  }

.feature_pannel_mobile
{
  margin-top: 77px;
  //min-height: 418px;
  .angle_icon_head
  {
    display:flex;
    font-size: 19px;
    margin-bottom: 9px;
    .angle_icon
    {
      display:flex;
      a
      {
        color: rgb(150, 92, 137);
        margin-right: 5px;
        opacity: 0.5;
      }
    }
  }
  .ant-collapse
  {
     border:0;
  }
  .ant-collapse-item
  {
    margin:20px 0px 20px 0px;
    border-bottom: 1px solid #707070;
    .ant-collapse-content
    {
      border:0;
    }
  }
}
//cann
.company-job-main {
  .company-job-card {
    background: #ffffff 0% 0% no-repeat padding-box;
    box-shadow: 0px 0px 12px #00000029;
    overflow-y: auto;
    overflow-x: hidden;
    .ant-collapse > .ant-collapse-item > .ant-collapse-header {
      color:#4B426B;
      font-size:16pt;
      font-family:poppins,sans-serif;
      background-color:#ffffff;
      text-overflow: ellipsis;
      overflow: hidden;
      white-space: nowrap;
    }
    .ant-collapse > .ant-collapse-item {
      border-bottom: none;
      /* margin-bottom: 30px; */
  }
    .ant-collapse > .ant-collapse-item {
      border-bottom: 2px solid #d9d9d9;
    }
    .ant-collapse-content > .ant-collapse-content-box {
      background-color:#ffffff;
    }
    .job-heading {
      display: flex;
      justify-content: space-between;
      padding-left: 24%;
      padding-top: 27px;
      .job-title {
        word-wrap: break-word;
        overflow: hidden;
        color: #4B426B;
        font-size: 29px;
        font-weight: bold;
        line-height: 1.2;
        font-family:poppins,sans-serif;
      }

      .heading {
        margin-right: 30px;
      }
      .apply-button {
        background: transparent linear-gradient(250deg, #68439a 0%, #aa5b8c 100%) 0% 0% no-repeat padding-box;
        color: #fff !important;
        border-radius:16pt;
        width: 100pt;
        height: 38pt;
        font-size: 20pt;
        text-align: center;
        font-weight: 600;
        
        // margin-left: 14px;
      }
    }

    .company-job-details {
      .keypoints {
        margin-left: 30px;
      }
      // margin-top: 59px;
      .text {
       // display: inline-block;
        margin-left: 35px;
        font-size: 15pt;
        color:#4B426B;
        font-weight:600;
        margin-bottom: 5px;
        margin-top: -11px;
        font-family:poppins,sans-serif;
      }
      .company-job-filter-value {
        font-size: 10pt;
        margin-left: 14px;
        margin-top: -5px;
        font-weight: normal;
        color: #4B426B;
        font-family:poppins,sans-serif;
      }
      .text-output {
        overflow-x: hidden;
      }
      .c-1,
      .c-2 {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        justify-content: space-between;
        // padding-left: 33px;
      }
      .circle,
      .common {
        margin-top: 40px;
      }
    }
    .title{
      font-weight:600;
      color: #4B426B;
      font-family:poppins,sans-serif;
      font-size: 14pt;
      text-transform: uppercase;
    }
  }
  .company-job-item-apply-btn {
    background:linear-gradient(90deg, #965C89 0%, #694B94 100%);
    color: #FFFFFF;
    border-radius: 11px;
    width: 110px;
    height: 35px;
    margin-bottom: 13px;
    font-family: 'Poppins', sans-serif;
    font-weight: 600;
    font-size: 14pt;
    margin-left: 83px;
    margin-top:39px;
  }
  .company-job-item-wrapper-title{
    margin: 10px 0px 20px 10px;
    font-size: 25px;
    font-family: 'Poppins', sans-serif;
    font-weight: 600;
    color: $new-primary-color;
}
}
.footer-wrapper-mobile {
  margin-top: 80px;
  height: 189px;
  padding: 20px 0;
  background: #C4C2C2 0% 0% no-repeat padding-box;
}
}

.enroll-model
{
  padding-bottom: 30px;
  .enroll-title{
    font-weight: 500;
    font-size: 35px;
    font-family: poppins,sans-serif;
    color: #4B426B;
    @media only screen and (max-width: 580px) 
    {
    font-size: 25px;
    }
  }
  .enroll-button
  {
    float: right;
  }
  .ant-form-item-label
  {
    text-align: initial;
    color: #4B426B;
    font-family:poppins,sans-serif;
    font-size: 15pt;
  }
  @media only screen and (max-width: 580px)
  {
    .ant-form-item-label, .ant-form-item-control-wrapper {
      display: block;
      width: 66%;
  }
 
  }
  .ant-form-item-label > label::after
  {
    display: none;
  }
  .ant-form-item
  {
    margin-bottom: 0px;
  }
  .ant-input
  {
    border:1px solid #4B426B;
    border-radius: 7px;
    @media only screen and (max-width: 580px) 
      {
        height: 25px;
      }
  }
  .ant-form-item-label > label
  {
    color: #4B426B;
  }
  .ant-form label
  {
    font-size:17px;
    font-family:poppins,sans-serif;
    @media only screen and (max-width: 580px) 
      {
        font-size: 10px;
      }
  }
  .ant-col-4{
    width: 30.666667%;
  }
  @media only screen and (max-width: 580px) 
    {
  .ant-col-4 
  {
    width: 32.666667%;
  }

}
  
  .ant-checkbox + span
  {
    color: #4B426B;
    font-size:17px;
    font-family:poppins,sans-serif;
    
    @media only screen and (max-width: 580px) 
    {
      font-size: 11px;
      white-space: nowrap;
    }
  }
 
  .enroll-btn{
    font-size: 19px;
    width: 117px;
    height: 35px;
    color: white;
    background: linear-gradient(
90deg
, #965C89,#694B94);
    font-family: poppins,sans-serif;
  }
  .ant-form-explain
  {
    white-space: nowrap;
  }
}
.enrol-modal-pick-shadow
{
  .ant-modal-content
  {
   filter:drop-shadow(0pt 12pt 15pt #760FAC29);
  }
  
}
.WOBSlider
{
  .carousel-indicators
  {
    display: none;
  }
  .carousel-control-prev,.carousel-control-next
  {
    width: 3%;
  }
}
.view_port_height
{
  //min-height: 84vh;
  @media only screen and (min-height: 640px) and (max-height: 730px)
  {
    min-height: 79vh;
  }
  @media only screen and (min-height: 915px) 
  {
    min-height: 85vh;
  }
  @media only screen and (min-height: 731px) and (max-height : 731px)
  {
    min-height: 82vh;
  }
  @media only screen and (min-height: 812px) 
  {
    min-height: 83vh;
  }
}
#landing_solution
.ant-card-meta-detail{
  height:154px;
}
.infinite-scroll-component {
  overflow:visible !important;
}